import React, { ReactNode } from 'react'
import { flexRender } from '@tanstack/react-table'
import { SortOrderButton } from '@mm/ui/src/components/SharedTable/utils'
import { ColResizer } from '@mm/ui/src/components/SharedTable/styles'
import { TableHeaderCell } from './styles'
import { TableHeaderCellProps, Totals } from './types'

export const customSortingFn = (rowA, rowB, columnId) => {
  const valueA = parseFloat(rowA.getValue(columnId) || 0)
  const valueB = parseFloat(rowB.getValue(columnId) || 0)
  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0
}

const isURL = (value: string) => value?.includes('https://')

export const renderCellValue = (cellValue: string, flexRender: any) => {
  if (isURL(cellValue)) {
    return (
      <a href={cellValue} target="_blank" rel="noopener noreferrer">
        {flexRender}
      </a>
    )
  }
  return flexRender
}

export const countColumnsBeforeLastLevel = columns =>
  columns.reduce(
    (count, item) =>
      count +
      (item.columns && item.columns.length > 0
        ? countColumnsBeforeLastLevel(item.columns)
        : 1),
    0
  )

export const getPowertableTotals = (
  totals: Totals,
  firstColumnGroupValues: number
) => {
  const powertableTotalsValues = Object.values(totals)

  powertableTotalsValues.sort((a, b) => a.order - b.order)

  const powertableTotals = powertableTotalsValues.map(({ value, metric }) => ({
    value,
    metric,
  }))

  const prefix = Array(firstColumnGroupValues).fill('')
  return [...prefix, ...powertableTotals]
}

export const HeaderCell = ({
  colSpan,
  column,
  table,
  isSticky = false,
  isPlaceholder,
  stickyLeft,
  windowWidth,
  isExportPdf,
  getSize,
  getContext,
  getResizeHandler,
}: TableHeaderCellProps) => (
  <TableHeaderCell
    colSpan={colSpan}
    style={{
      width: getSize(),
      display: 'flex',
      justifyContent: isSticky ? 'flex-start' : 'center',
      ...(isExportPdf ? {} : { left: windowWidth > 1023 ? stickyLeft : 0 }),
    }}
    title={
      typeof column.columnDef.header === 'string'
        ? column.columnDef.header || ''
        : ''
    }
    isExportPdf={isExportPdf}
    isSticky={isSticky}
    className={`${column.columnDef?.meta?.className ?? ''} ${isPlaceholder ? 'isPlaceholder' : ''}`}
  >
    <span>
      {isPlaceholder ? null : flexRender(column.columnDef.header, getContext())}
    </span>
    {isPlaceholder || isExportPdf
      ? null
      : column.getCanSort() && <SortOrderButton column={column} />}

    {isPlaceholder || isExportPdf ? null : (
      <ColResizer
        onDoubleClick={() => column.resetSize()}
        onMouseDown={getResizeHandler()}
        onTouchStart={getResizeHandler()}
        className={`${table.options.columnResizeDirection} ${column.getIsResizing() ? 'isResizing' : ''}`}
      />
    )}
  </TableHeaderCell>
)
