import { useQuery } from '@tanstack/react-query'
import statTableApi from '../index'

export const GET_STATTABLE_DATA_KEY = 'StatTables_GetTableData'

export const getStatTableData = async (
  id: string,
  shareable: string,
  pdf: string,
  dashboard: any
) => {
  let queryParams
  if (shareable) {
    queryParams = `?shareable=${shareable}&dashboard=${dashboard.id}`
  } else if (pdf) {
    queryParams = `?pdf=${pdf}&dashboard=${dashboard.id}`
  }

  return statTableApi
    .get(`/${id}/data${queryParams ? queryParams : ''}`)
    .then(response => response.data?.data)
}

const useGetStatTableData = ({
  id,
  shareable,
  pdf,
  dashboard,
}: {
  id: string
  shareable: string
  pdf: string
  dashboard: any
}) =>
  useQuery(
    [GET_STATTABLE_DATA_KEY, id],
    () => getStatTableData(id, shareable, pdf, dashboard),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export { useGetStatTableData }
