import styled from 'styled-components'
import { CardFooter } from 'reactstrap'

export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

export const StyledCardFooter = styled(CardFooter)`
  width: 100%;
  padding: 0px 1.25rem;
  background: white;
  border: none;

  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`
