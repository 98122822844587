/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.User Validations
04.Menu
05.Error Page
06.Alerts
07.Dashboards
08.Onboarding
09.Overview
10.DataSources
11.AccountClients
12.Configure Integrations
13.Widget Error Messages
14.Custom Metrics
15.Google Sheet
16.Errors
*/
const language = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',
  'general.and': 'e a',
  'general.terms': 'termos',
  'general.privacy-policy': 'política de privacidade',
  'general.accept': 'Aceite',
  'general.by': 'by',
  'general.is': 'é',
  'general.free': 'grátis',
  'general.forever': 'para sempre',
  'general.skip': 'Pular',
  'general.back': 'Atrás',
  'general.confirm': 'Confirmar',
  'general.next': 'Seguinte',
  'general.cancel': 'Cancelar',
  'general.need-help': 'Necesitas ayuda?',
  'general.clear-sort': 'Limpar ordenação',
  'general.view': 'Ver',
  'general.success': 'Sucesso',
  'general.try-again': 'Tente novamente',
  'general.edit': 'Editar',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Iniciar sessão',
  'user.login-main-title':
    'A solução tudo-em-um para agências e equipes de marketing',
  'user.register-looker-plan':
    'Agora com integração ao Looker Data Studio, Exporte para Sheets, configure alertas de controle e visualize seus dados de marketing com facilidade.',
  'user.register': 'Registro',
  'user.email': 'E-mail',
  'user.company-email': 'E-mail corporativo',
  'user.password': 'Senha',
  'user.confirm-password': 'Confirmar senha',
  'user.show-password': 'Mostrar senha',
  'user.hide-password': 'Ocultar senha',
  'user.forgot-password-title': 'Esqueci minha senha',
  'user.forgot-password-question': 'Esqueceu a senha?',
  'user.forgot-password-send-link': 'Redefinir a senha',
  'user.forgot-password-back-login': 'Voltar ao login',
  'user.forgot-password-create-account': 'Criar nova conta',
  'user.back-to-forgot-password': 'Voltar para esqueci minha senha',
  'user.recover-password': 'Recuperar senha',
  'user.recover-password-save': 'Salvar senha',
  'user.fullname': 'Nome completo',
  'user.login-button': 'Iniciar sessão',
  'user.register-button': 'Registro',
  'user.new-user': 'Você é novo no Master Metrics?',
  'user.cannot-access-account': 'Não consegue acessar sua conta?',
  'user.privacy-policy': 'Política de Privacidade',
  'user.terms-service': 'Termos de Serviço',
  'user.terms-and': 'e',
  'user.reset-password-button': 'Reset',
  'user.buy': 'BUY',
  'user.username': 'Username',
  'user.api': 'Close API key',
  'user.phone': 'Telefone',
  'user.crm': 'What CRM do you use?',
  'user.existent-account': 'Você já tem uma conta Master Metrics?',
  'user.mm-existent-account': 'Você já tem uma conta?',
  'user.hubspot-register-title':
    'Painéis de Marketing para clientes da Hubspot',
  'user.hubspot-register-subtitle':
    'Calcule o custo por cada etapa do funil por campanha de anúncios ou por conta de anúncios.',
  'user.overview-sheet-register-title':
    'Supervisione todos os dados dos clientes da sua agência neste painel',
  'user.overview-sheet-register-subtitle':
    'Você precisa verificar 20 painéis diferentes para ter uma visão completa da sua agência? Baixe gratuitamente',
  'user.forgot-password-error': 'Erro de Esquecimento de Senha',
  'user.login-error': 'Erro de Início de Sessão',
  'user.signup-error': 'Erro de Registro',
  'user.forgot-password-success': 'Email enviado com sucesso',
  'user.recover-password-error': 'Erro na Recuperação de Senha',
  'user.recover-password-success': 'Recuperação de Senha Bem-Sucedida',

  // 03.User Validations
  'validation.fullname': 'Somente caracteres alfabéticos são permitidos',
  'validation.fullname-required': 'O nome completo é obrigatório',
  'validation.email-invalid': 'E-mail inválido',
  'validation.email-required': 'E-mail é obrigatório',
  'validation.company-email':
    'Por favor, insira seu endereço de e-mail comercial',
  'validation.password-min': 'A senha deve ter pelo menos 6 caracteres',
  'validation.password-required': 'Senha é obrigatória',
  'validation.password-number': 'A senha deve incluir pelo menos 1 número',
  'validation.password-match': 'As senhas devem coincidir',
  'validation.confirm-password-required': 'Confirmar senha é obrigatório',
  'validation.terms':
    'A Política de Privacidade e os Termos de Serviço devem ser aceitos',

  /* 03.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.metrics': 'Metrics',
  'menu.dashboard': 'Dashboard',
  'menu.add-dashboard': '+ Add Dashboard',

  'modal.basic': 'modal basic',

  'modal.launch-demo-modal': 'demo modal',

  'modal.modal-title': 'modal title',

  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.error-boundary.title': 'Ops! Algo deu errado.',
  'pages.error-boundary.message':
    'Pedimos desculpas pelo transtorno. Por favor, tente atualizar a página e, se o problema continuar',
  'pages.error-boundary.message-request-assistance':
    'solicite assistência imediatamenteformat',

  /* 05.AppSections */
  'app.alerts': 'Alerts',
  'app.dashboards': 'Dashboards',
  'app.overviews': 'Overview',

  /* 06.Alerts */
  'alerts.register-title':
    'Set up alerts to control underperforming campaigns and',
  'alerts.register-title-highlight': 'Sleep peacefully',
  'alerts.free-price': "Price? Don't worry",
  'alerts.main-title':
    'Receba notificações quando uma métrica de campanha estiver abaixo do esperado.',

  /* 07.Dashboards */
  'dashboards.register-title-1': 'Crie',
  'dashboards.dashboards': 'painés',
  'dashboards.register-title-2': 'para suas campanhas de marketing',

  'dashboards.register-title-highlight': '100% gratuito!',
  'dashboards.free-price':
    'Pare de pagar para enviar seus dados de anúncios do Facebook para o Looker Studio.',
  'dashboards.main-title': 'Crie seu painel de marketing gratuitamente',
  'dashboards.simpletable-error-loading-table': 'Erro ao carregar tabela',
  'dashboards.simpletable-server-error':
    'O servidor não pôde processar os dados da tabela',
  'dashboards.no-metrics-selected-for-table':
    'Não há métricas selecionadas para esta tabela',
  'dashboards.error-loading-dashboard-title': 'Erro ao carregar os painéis',
  'dashboards.error-loading-dashboard-message':
    'Falha ao carregar os painéis, por favor tente novamente ou entre em contato com o suporte',
  'dashboards.powertable-duplicate-success-message':
    'Powertable foi duplicado com sucesso',
  'dashboards.widget-duplicate-success-message': 'foi duplicado com sucesso',

  /* 08.Onboarding */
  'onboarding.title': 'Configurações de Conta',
  'onboarding.free-dashboards-description':
    'Ao adicionar suas fontes de dados e contas, criaremos relatórios para você. Você terá tudo criado em segundos sem nenhum esforço',
  'onboarding.description':
    'Ao adicionar suas fontes de dados e contas, criaremos tabelas de visão geral, alertas e relatórios para você. Você terá tudo criado em segundos sem nenhum esforço',
  'onboarding.source-selection': 'Selecione a fonte para conectar',
  'onboarding.connected-sources': 'Fontes conectadas',
  'onboarding.loading-configuration':
    'Preparando os melhores relatórios que você já viu para seus clientes.',
  'onboarding.modal-title': 'Pular configurações de Conta',
  'onboarding.modal-body':
    'Você quer começar com uma conta Demo com dados de teste? Ou você prefere começar com uma conta vazia, adicionar seus dados das plataformas de publicidade e depois criar os relatórios manualmente?',
  'onboarding.modal-scratch-button': 'Começar do zero com meus dados',
  'onboarding.modal-demo-button': 'Demonstração com dados de teste',
  'onboarding.end-demo': 'Encerrar Demonstração',
  'onboarding.end-demo-modal-text':
    'Tem certeza de que deseja encerrar a demonstração? Todos os dados de demonstração serão removidos para que você possa começar do zero',
  'onboarding.add-source-end-demo-modal-text':
    'Para conectar sua fonte de dados, é necessário finalizar a demonstração. Tem certeza de que deseja encerrar a demonstração? Todos os dados de demonstração serão removidos para que você possa começar do zero',
  'onboarding.end-demo-back-to-configuration':
    'Confirmar e ir para a configuração da conta',

  /* 09. Overview */
  'clients.configuration': 'Configuração de clientes',
  'clients.add-client': 'Adicionar cliente',
  'clients.remove-client': 'Excluir cliente',
  'clients.remove-client-text':
    'Tem certeza de que deseja excluir este cliente?',
  'overview.register-title-1': 'Todas as suas',
  'overview.overviews': 'contas de anúncios',
  'overview.register-title-2': 'em uma única visualização',
  'overview.free-price':
    'Você sabe como é o desempenho dos seus clientes? Ou você tem que ler 20 relatórios para ver?',
  'overview.call-to-action': 'Experimente Free Overview',
  'overviews.main-title':
    'Crie sua tabela de visão geral de marketing gratuitamente',
  'overviews.create-view-title-message': 'Criação de visão',
  'overviews.create-view-message': 'Criando nova visão',

  /* 10. DataSources */
  'datasource.add-source': 'Adicionar fonte',
  'datasource.close-api-key-placeholder': 'Inserir chave de API Close',

  /* 11. AccountClients */
  'account-clients.add-new': 'Adicionar uma conta',
  'account-clients.add-new-description':
    'Para agências, insira o nome da conta do cliente. Para empresas, insira o nome da sua empresa',
  'account-clients.schedule-a-call': 'Agendar uma chamada com nossa equipe',
  'account-clients.client-type-label': 'Tipo de Negócio',
  'account-clients.client-name-label': 'Nomeie sua Conta',
  'account-clients.client-type-placeholder': 'Selecionar Tipo de Negócio',
  'account-clients.client-name-placeholder': 'Exemplo: Conta de Demonstração',
  'account-clients.client-account-placeholder': 'Selecionar Conta',
  'account-clients.client-source-placeholder': 'Selecionar Fonte',
  'account-clients.client-budget-placeholder':
    'Inserir orçamento mensal da Conta',
  'account-clients.client-conversion-metric-placeholder':
    'Selecionar métrica chave de conversão',
  'account-clients.client-account-label': 'Conta de Anúncio',
  'account-clients.client-source-label': 'Fonte',
  'account-clients.client-budget-label': 'Orçamento mensal',
  'account-clients.client-conversion-metric-label':
    'Métrica chave de conversão',
  'account-clients.first-source-connection':
    'Parece que é a primeira vez que você usa esta fonte, permissão é necessária para obter os dados da fonte',
  'account-clients.client-type-error':
    'Por favor, selecione um tipo de cliente',
  'account-clients.account-name-error':
    'Por favor, insira um nome para a conta',

  /* 12. Configure Integrations */
  'configure-integration.title': 'Configurando a fonte',
  'configure-integration.description':
    'Aguarde um momento enquanto terminamos de configurar os últimos detalhes de sua nova fonte de dados.',

  /* 13. Widget Error Messages */
  'token-revalidate-alert.message':
    'Ocorreu um problema com {source}. Para restabelecer',
  'token-revalidate-alert.link': 'clique aqui para reconectar',
  'token-revalidate-missing-permissions.message':
    'As permissões para a conta de anúncios "{account}" da fonte "{source}" foram perdidas. Por favor, verifique-as ou remova a conta.',
  'token-revalidate-missing-permissions-button.message':
    'Ir para Fontes de Dados',
  'token-revalidate-error.message':
    'Sua conexão com "{source}" precisa ser reconectada, por favor clique no botão a seguir.',
  'token-revalidate-button.message': 'Reconectar',
  'widget-no-data-was-found.message':
    'Nenhum dado foi encontrado para este período.',
  'widget-default-error.message':
    'Ocorreu um erro inesperado, por favor tente novamente mais tarde.',
  'widget-no-data-was-found-button.message': 'Editar Widget',
  'widget-meta-social-is-required.message':
    'Para usar essas métricas, você precisa adicionar o datasource social do Facebook',
  'widget-instagram-social-is-required.message':
    'Para usar essas métricas, você precisa adicionar o datasource social do Instagram',
  'widget-meta-social-no-data-on-facebook-ads.message':
    'Nenhuma postagem social paga no período selecionado',
  'widget-instagram-no-data-on-facebook-ads.message':
    'Nenhuma postagem paga no Instagram no período selecionado',
  'widget-meta-user-without-meta-social.message':
    'Para usar essas métricas, você deve adicionar o recurso de dados meta social',
  'widget-meta-user-without-instagram-social.message':
    'Para usar essas métricas, você deve adicionar o datasource social do Instagram',
  'widget-meta-user-without-facebook-ads.message':
    'Para usar essas métricas, você precisa adicionar o recurso de dados do Facebook Ads',

  /* 15. Google Sheet */
  'modal.selectGoogleSheet.title': 'Selecionar Google Sheet',
  'modal.selectGoogleSheet.sheet': 'Folha',
  'modal.selectGoogleSheet.sheetDetails': 'Detalhes da Folha',
  'modal.selectGoogleSheet.useFirstRowAsHeaders':
    'Usar a primeira linha como cabeçalhos',
  'modal.selectGoogleSheet.tableRangeViewChecked':
    'Incluir intervalo específico',
  'modal.selectGoogleSheet.specificRangePlaceholder': 'Exemplo A1:B50',
  'modal.selectGoogleSheet.continue': 'Continuar',
  'modal.selectGoogleSheet.options': 'Opções',
  'modal.selectGoogleSheet.invalidRange':
    'Formato de intervalo inválido. Use "A1:Z999"',
  'modal.selectGoogleSheet.selectSheet': 'Por favor selecione uma folha',
  'modal.selectGoogleSheet.selectSheetView':
    'Por favor selecione uma visualização da folha',
  'modal.selectGoogleSheet.error':
    'Ocorreu um erro ao selecionar a folha do Google. Por favor, tente novamente.',
  'modal.selectGoogleSheet.emptySheet':
    'A planilha ou o intervalo selecionado não tem dados',
  'modal.selectGoogleSheet.apply': 'Aplique',
  'modal.selectGoogleSheet.reconnect': 'Reconectar',
  'modal.selectGoogleSheet.reconnect-title': 'Aplicar alterações na conexão?',
  'formSpreadsheetReconnect.configuration': 'Configuração',
  'formSpreadsheetReconnect.noConfigChanges': 'Nenhuma mudança na configuração',
  'formSpreadsheetReconnect.fields': 'Campos',
  'formSpreadsheetReconnect.missingFields': 'Falta informação nos campos:',
  'formSpreadsheetReconnect.allFieldsComplete':
    'Todos os campos estão completos',
  'formSpreadsheetReconnect.modifiedFields': 'Campos modificados:',
  'formSpreadsheetReconnect.noModifiedFields': 'Não há campos modificados',
  'formSpreadsheetReconnect.newFields': 'Campos novos:',
  'formSpreadsheetReconnect.noNewFields': 'Não há campos novos',
  'formSpreadsheetReconnect.existChanges':
    'As configurações da planilha foram alteradas ou estão incorretas. Atualize as configurações',
  'widget-default-error.goto': 'Ir para',
  'modal.selectGoogleSheet.helpLinkText':
    'Como importar dados do Google Sheets',

  /* 14.Custom Metrics */

  'custommetrics-calculated-metrics': 'Métricas Calculadas',
  'custommetrics-name': 'Nome',
  'custommetrics-display-name': 'Nome de Exibição',
  'custommetrics-add-metric-display-name': 'Adicionar nome de exibição',
  'custommetrics-type-formula':
    'Digite uma fórmula / use @ para adicionar métrica',
  'custommetrics-eg-metric': 'Ex.: "[Nome da Métrica 1] / 2"',
  'custommetrics-decimals': 'Decimais',
  'custommetrics-choose-decimals': 'Escolha a quantidade de decimais',
  'custommetrics-format': 'Formato',
  'custommetrics-choose-result-format': 'Escolha o formato do resultado',
  'custommetrics-eg-would-display': 'Ex.: 0.54357 seria exibido como',
  'custommetrics-cancel': 'Cancelar',
  'custommetrics-save': 'Salvar',
  'custommetrics-+add': '+ Adicionar',
  'custommetrics-update': 'Atualizar',
  'custommetrics-add': 'Adicionar',
  'custommetrics-query-metric': 'Métrica de Consulta',
  'custommetrics-add-name': 'Adicionar um nome',
  'custommetrics-source': 'Fonte',
  'custommetrics-choose-source': 'Escolha a fonte',
  'custommetrics-error': 'Erro!',
  'custommetrics-add-metric': 'Adicionar Métrica',
  'custommetrics-query-metrics': 'Métricas de Consulta',
  'custommetrics-search-by-name': 'Pesquisar por nome',
  'custommetrics-add-query-metrics': 'Adicionar métricas de consulta',
  'custommetrics-no-calculated-metric':
    'Você não tem nenhuma métrica calculada',
  'custommetrics-click-to-add-one': 'Clique no botão abaixo para adicionar uma',
  'custommetrics-add-calculated-metric': 'Adicionar Métrica Calculada',
  'custommetrics-delete-this': 'Tem certeza de que deseja excluir isso?',
  'custommetrics-metric': 'Métrica',
  'custommetrics-add-metric-name': 'Adicionar nome da métrica',
  'custommetrics-edit': 'Editar',
  'custommetrics-add-query-metric': 'Adicionar Métrica de Consulta',
  'custommetrics-displayname-helptooltip':
    'Este nome pode ser incluído em widgets do painel, como tabelas e gráficos. Caso nenhum valor seja inserido, o nome de exibição será o nome da métrica personalizada',

  /** 16.Errors */
  'errors.ad-accounts-limit-exceeded':
    'O limite de contas de anúncios foi excedido. Para adicionar mais uma, você precisa de um plano personalizado. Entre em contato com o suporte',
  'errors.datasource-limit-exceeded-long':
    'Limite de fontes de dados excedido. Para adicionar mais uma, você precisa de um plano personalizado. Entre em contato com o suporte',
  'errors.datasource-limit-exceeded': 'Limite de fontes de dados excedido.',
  'errors.payment-requirement': 'Pagamento necessário',
  'errors.payment_required': 'Pagamento necessário',
}

export default language
