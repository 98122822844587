import React, { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { capitalizeString } from '@mm/utils/src/helpers/Utils'
import {
  StyledCardBody,
  StyledCardText,
  StyledCardWithError,
  StyledDots,
  StyledLead,
  StyledSpan,
  StyledViewOptions,
} from './style'
import { CompareMarker } from './CompareMarker'

interface RezizableTextProps {
  formatedValue: string | number
  currentValue: string | number
  previousPeriod?: string | number
  hideActions?: boolean
  metric: {
    name: string // Nombre que se le puso a la metrica
    type: string // metrica usada
    displayName: string
  }
  altMessage: {
    message: string
  }
  altMessageColor: string
  dateString: string
  handleToggleTooltip: () => void
  error?: string
  errorComponent?: React.ReactNode
  compare?: boolean
  previousDataString?: string
  widgetId?: string
  formatedPreviousPeriod?: string
  name?: string
}

const ResizableText = ({
  formatedValue,
  currentValue,
  previousPeriod,
  hideActions,
  metric,
  altMessage,
  altMessageColor,
  dateString,
  handleToggleTooltip,
  error,
  previousDataString,
  widgetId,
  formatedPreviousPeriod,
  name,
  errorComponent,
}: RezizableTextProps) => {
  const [fontSize, setFontSize] = useState(16)
  const { ref, height, width } = useResizeObserver<HTMLDivElement>()

  const getFontSize = () => {
    if (!width || !height) return
    const scaleFactor = width >= height ? height / 125 : width / 125
    const newSize = Math.round(16 * scaleFactor)
    setFontSize(newSize)
  }

  useEffect(() => {
    getFontSize()
  }, [height, width])

  if (errorComponent) {
    return (
      <StyledCardWithError>
        <div
          ref={ref}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <StyledViewOptions
            onClick={handleToggleTooltip}
            className="view-options"
            onMouseDown={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
          >
            {hideActions && <StyledDots className="dots"></StyledDots>}
          </StyledViewOptions>

          {errorComponent}
        </div>
      </StyledCardWithError>
    )
  }

  return (
    <StyledCardBody>
      <div
        ref={ref}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          height: '100%',
        }}
      >
        <StyledViewOptions
          onClick={handleToggleTooltip}
          className="view-options"
          onMouseDown={e => e.stopPropagation()}
          onMouseMove={e => e.stopPropagation()}
        >
          {hideActions && <StyledDots className="dots"></StyledDots>}
        </StyledViewOptions>

        <div style={{ margin: 'auto' }}>
          {error ? (
            <StyledSpan
              style={{
                color: altMessageColor,
                fontSize: `${fontSize * 0.55}px`,
              }}
            >
              {error}
            </StyledSpan>
          ) : (
            <>
              <StyledCardText
                style={{
                  fontSize: `${fontSize * 1}px`,
                  marginTop: 15,
                  marginBottom: `${fontSize * 0.6}px`,
                }}
              >
                {formatedValue}
              </StyledCardText>
              {previousPeriod !== undefined ? (
                <div style={{ fontSize: `${fontSize * 0.7}px` }}>
                  <CompareMarker
                    fontSize={fontSize * 0.7}
                    metric={metric.type}
                    comparedValue={Number(previousPeriod)}
                    formatedPreviousPeriod={formatedPreviousPeriod}
                    current={Number(currentValue)}
                    previousDataString={previousDataString}
                    widgetId={widgetId}
                  ></CompareMarker>
                </div>
              ) : null}

              <StyledLead style={{ fontSize: `${fontSize * 0.8}px` }}>
                {capitalizeString(name || metric.displayName || metric.name)}
                <StyledSpan
                  style={
                    altMessage
                      ? {
                          color: altMessageColor,
                          fontSize: `${fontSize * 0.6}px`,
                        }
                      : {}
                  }
                >
                  {altMessage?.message
                    ? altMessage.message
                    : altMessage
                      ? altMessage.toString()
                      : dateString}
                </StyledSpan>
              </StyledLead>
            </>
          )}
        </div>
      </div>
    </StyledCardBody>
  )
}

export default ResizableText
