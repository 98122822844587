import React, { useState, useEffect } from 'react'
import {
  SectionSelectionWrapper,
  SectionOption,
  AddSectionWrapper,
  IconWrapper,
  OptionText,
} from './styles'

import IconComponent from '../IconComponent/IconComponent'
import { IconNames, IconVariable } from '../IconComponent/IconComponent'
import Tooltip from './Tooltip'

type SectionItem = {
  id?: string
  name?: string
  order?: number
  [key: string]: any
}

interface SectionSelectionTypes {
  changeActiveSection: (dashboard: SectionItem | null) => void
  handleAddSection?: () => void
  handleSectionTitleBlur: (
    activeSectionId: string,
    sections: SectionItem
  ) => Promise<any>
  onDelete?: () => void
  onDuplicate?: () => void
  sections: SectionItem[]
  activeSection: SectionItem | null
  className?: string
  updateSectionsOrder?: (newSections: SectionItem[]) => void
}

const SectionSelection = ({
  sections,
  activeSection,
  changeActiveSection,
  handleAddSection,
  onDelete,
  onDuplicate,
  className,
  updateSectionsOrder,
  handleSectionTitleBlur,
}: SectionSelectionTypes) => {
  const isOverview = className === 'overview'
  const [localSections, setLocalSections] = useState(sections)
  const [draggedSection, setDraggedSection] = useState<SectionItem | null>(null)
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null)

  const [showTooltip, setShowTooltip] = useState(false)
  const [editTitle, setEditTitle] = useState(false)
  const [currentActiveSection, setCurrentActiveSection] =
    useState<SectionItem | null>(null)
  const [localTitle, setLocalTitle] = useState(activeSection?.name)

  const isDraggable = !!updateSectionsOrder && sections.length > 1

  const handleDragStart = (section: SectionItem, index: number) => {
    setDraggedSection(section)
    setDraggingIndex(index)
  }

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    overSection: SectionItem,
    index: number
  ) => {
    e.preventDefault()

    if (!draggedSection || draggedSection.id === overSection.id) return

    const draggedIndex = localSections.findIndex(
      s => s.id === draggedSection.id
    )
    const overIndex = index

    const updatedSections = [...localSections]
    const [movedSection] = updatedSections.splice(draggedIndex, 1)
    updatedSections.splice(overIndex, 0, movedSection)

    setLocalSections(updatedSections)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!draggedSection || draggingIndex === null || !updateSectionsOrder)
      return

    const reorderedSections = localSections.map((section, index) => ({
      ...section,
      [isOverview ? 'viewOrder' : 'order']: index,
    }))
    updateSectionsOrder(reorderedSections)

    setDraggedSection(null)
    setDraggingIndex(null)
  }

  const toogle = () => {
    setShowTooltip(!showTooltip)
  }

  const onEdit = () => {
    setEditTitle(true)
  }

  const onTitleChange = (e: any) => {
    if (currentActiveSection) {
      setLocalTitle(e.target.value)
    }
  }

  const onTitleblur = async () => {
    if (!activeSection) return
    await handleSectionTitleBlur(activeSection.id!, {
      ...currentActiveSection,
      id: currentActiveSection!.id,
      name: localTitle || 'Untilted',
    })
    setEditTitle(false)
  }

  useEffect(() => {
    if (activeSection) {
      setCurrentActiveSection({ ...activeSection })
      setLocalTitle(activeSection.name)
    }
  }, [activeSection])

  useEffect(() => {
    setLocalSections(sections)
  }, [sections])

  return (
    <SectionSelectionWrapper
      className={`scrollbar-color scrollbar-vertical-color ${className}`}
    >
      {localSections.map((section, index) => (
        <SectionOption
          active={section?.id === activeSection?.id}
          key={section.id}
          draggable={isDraggable}
          onDragStart={() => handleDragStart(section, index)}
          onDragOver={e => handleDragOver(e, section, index)}
          onDragEnd={handleDrop}
          onClick={() => changeActiveSection(section)}
        >
          {editTitle && section.id === activeSection?.id ? (
            <input
              type="text"
              style={{ display: 'flex', width: '100%' }}
              value={localTitle || ''}
              onChange={e => {
                onTitleChange(e)
              }}
              onBlur={onTitleblur}
            />
          ) : (
            <OptionText>{section.name}</OptionText>
          )}
          {onDelete && (
            <IconWrapper
              onClick={toogle}
              style={{
                display: section?.id === activeSection?.id ? '' : 'none',
              }}
            >
              <IconComponent
                icon={IconNames.Dots}
                variable={IconVariable.Black}
              />
            </IconWrapper>
          )}
          <div
            style={{
              position: 'relative',
              top: '-36px',
              right: '-58px',
            }}
          >
            <Tooltip
              show={showTooltip && section?.id === activeSection?.id}
              toggle={toogle}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
            />
          </div>
        </SectionOption>
      ))}

      {handleAddSection && (
        <AddSectionWrapper>
          <div onClick={handleAddSection} style={{ cursor: 'pointer' }}>
            <IconComponent
              icon={IconNames.Plus}
              variable={IconVariable.Black}
            />
          </div>
        </AddSectionWrapper>
      )}
    </SectionSelectionWrapper>
  )
}

export default SectionSelection
