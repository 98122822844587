//@ts-nocheck
import { NotFound } from '@mm/ui'
import Loader from '@mm/ui/src/components/Loader'
import { useEffect, useMemo, useState } from 'react'
import { uptadeShareable } from '../api'
import GridLayoutComponent from '@mm/ui/src/components/GridLayout/GridLayoutComponent.jsx'
import { WidgetLayout } from '@mm/ui/src/components/GridLayout/types'
import DateSelectCard from '@mm/ui/src/components/DateSelectCard'
import moment from 'moment'
import { getDatesArray } from '@mm/utils/src/constants/ranges'
import { DateChosen } from '@mm/ui/src/components/Forms/WidgetForms/widget'
import queryClient from '@mm/api/src/reactQuery'
import DashboardExportComponent from '@mm/ui/src/components/GridLayout/DashboardExportComponent'
import DownloadIcon from '@mm/ui/src/components/svg/DownloadIcon.jsx'
import { calculateDate } from '@mm/ui/src/components/DateSelectCompareCard/utils'

import useGetSharedGroupDashboards from '@mm/api/src/dashboardGroups/requests/useGetSharedGroupDashboards'
import SectionSelection from '@mm/ui/src/components/SectionSelection'
import useGetSharedDashboard from '@mm/api/src/dashboards/requests/useGetSharedDashboard'

interface Dashboard {
  title: string
  logoUrl: string
  error: boolean
  layout: WidgetLayout[]
  allowFilter: boolean
  dateFilters: any
  widgets: any[]
}

const DashboardContainer = () => {
  const datesArray = getDatesArray()

  const [title, setTitle] = useState('Shared Dashboard')
  const [dateChosen, setDateChosen] = useState<DateChosen | null>(null)
  const [allowFilter, setAllowFilter] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')
  const [widgets, setWidgets] = useState<any[]>([])
  const [layout, setLayout] = useState<WidgetLayout[]>([])
  const [isExporting, setIsExporting] = useState(false)
  const [calculatedDate, setCalculatedDate] = useState(null)

  const uuid = window.location.pathname.replace('/', '')

  const [activeSection, setActiveSection] = useState<any | null>(null)

  const {
    data: groupSections,
    isLoading,
    error,
  } = useGetSharedGroupDashboards(uuid)

  const {
    data: dashboard,
    isFetching: loadingSection,
    error: sectionError,
  } = useGetSharedDashboard(activeSection?.id, uuid, {
    enabled: !!activeSection?.id,
  })

  const changeActiveSection = (dashboard: any) => {
    setActiveSection(dashboard)
  }

  const formatDateForDashboardApi = (date: any) => {
    return {
      dateFilterRange: date ? date.name : '',
      dateFilterFrom: date ? moment(date.from).format('YYYY-MM-DD') : '',
      dateFilterTo: date ? moment(date.to).format('YYYY-MM-DD') : '',
      dateFilterLastDays: date ? date.lastDays : '',
      dateFilterUntilToday: date ? date.untilToday : false,
    }
  }

  const handleDateChange = async (
    startDate: any,
    endDate: any,
    optionId: any,
    lastDays: any,
    untilToday: any
  ) => {
    //@ts-ignore
    const name = datesArray.find(item => item.id === optionId).name
    const from =
      startDate === null || startDate === undefined ? moment() : startDate
    const to = endDate === null || endDate === undefined ? moment() : endDate
    handleChangeDate({ name, from, to, lastDays, untilToday })
  }

  const handleChangeDate = async (date: any) => {
    try {
      const bodyToUpdate = formatDateForDashboardApi(date)
      await uptadeShareable(uuid, bodyToUpdate)
      setDateChosen(bodyToUpdate)
      await queryClient.invalidateQueries()
    } catch (error) {
      console.log(error)
    }
  }

  const onRemoveDateChosen = async () => {
    try {
      await uptadeShareable(uuid, {})
      setDateChosen(null)
      queryClient.invalidateQueries()
      queryClient.removeQueries()
    } catch (error) {
      console.log(error)
    }
  }

  const handleExportPDF = () => {
    setIsExporting(true)
  }
  const handleExportPDFFalse = () => {
    setIsExporting(false)
  }

  const fetchDashboardData = () => {
    if (!dashboard) return

    const isMobile = window.innerWidth <= 768
    const dashboardData = {
      texts: [],
      titles: '',
      error: false,
      allowFilter: false,
      dateFilters: {},
      layout: [],
      logoUrl: '',
      widgets: [],
    }

    dashboardData.titles = dashboard.dashboard.name
    dashboardData.allowFilter = dashboard.shareable.allowFilter
    dashboardData.dateFilters = dashboard.shareable.dateFilters
    dashboardData.layout = dashboard.dashboard.layout
    dashboardData.logoUrl = dashboard.shareable.image.url
    dashboardData.widgets = []
    if (dashboard.data.length) {
      for (const widget of dashboard.data) {
        dashboardData.widgets.push(widget)
      }
    }
    dashboardData.layout.forEach(widget => {
      if (
        ![
          'powertable',
          'powertile',
          'simpletable',
          'powerchart',
          'multiaxischart',
          'overview',
        ].includes(widget.type)
      )
        return

      if (
        (widget.type === 'powertable' || widget.type === 'multiaxischart') &&
        isMobile
      )
        return

      dashboardData.widgets.push({
        id: widget.i,
        type: widget.type,
        stringDate: widget.stringDate,
        edited: widget.edited,
      })
    })

    return dashboardData
  }

  const fetchData = async () => {
    // @ts-ignore
    const data = fetchDashboardData()
    if (data && !data.error) {
      let updatedDateFilterRange: any = {}

      if (data.dateFilters.dateFilterRange) {
        updatedDateFilterRange = calculateDate(
          data.dateFilters.dateFilterRange,
          data.dateFilters.dateFilterFrom,
          data.dateFilters.dateFilterTo,
          data.dateFilters.dateFilterLastDays,
          data.dateFilters.dateFilterUntilToday,
          false
        )
      }

      const updatedDateFilter = {
        ...data.dateFilters,
        dateFilterFrom: updatedDateFilterRange.from || null,
        dateFilterTo: updatedDateFilterRange.to || null,
        dateFilterRange: data.dateFilters.dateFilterRange || null,
      }

      setLayout(data.layout)
      setTitle(data.titles)
      setLogoUrl(data.logoUrl)
      setDateChosen(updatedDateFilter)
      setAllowFilter(data.allowFilter)
      const allWidgets = data.widgets
      setWidgets(allWidgets)
    }
  }

  useEffect(() => {
    fetchData()
  }, [dashboard, activeSection])

  const calulateDate = () => {
    const date = datesArray.find(
      existentDate => dateChosen?.dateFilterRange === existentDate?.name
    )
    setCalculatedDate(date)
  }

  useEffect(() => {
    calulateDate()
  }, [dateChosen])

  useEffect(() => {
    if (
      groupSections?.length &&
      (!activeSection ||
        (activeSection &&
          !groupSections.find(
            (section: any) => section.id === activeSection.id
          )))
    ) {
      setActiveSection(groupSections[0])
    }
  }, [groupSections])

  return (
    <>
      {error ? (
        <NotFound isPublic={true} />
      ) : (
        <>
          <div className="container" style={{ padding: '0 50px' }}>
            <div
              className="header"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <img alt="User Logo" src={logoUrl!} />
              <h1 style={{ padding: '0px', margin: 'auto' }}>{title}</h1>
              <div>
                {allowFilter && !isLoading && (
                  <DateSelectCard
                    selectedDateId={calculatedDate?.id}
                    dateChosen={dateChosen}
                    handleDateChange={handleDateChange}
                    onRemoveDateChosen={onRemoveDateChosen}
                    enableLastDays={true}
                    origin={'viewer'}
                  />
                )}
              </div>
            </div>
            <div className="separator" style={{ width: '100%' }}></div>
          </div>

          <div style={{ padding: '0 50px' }} className="shareable-container">
            {isLoading ? (
              <Loader style={{ height: '50vh' }} />
            ) : (
              <>
                <div className="main-view-container">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ maxWidth: '80%' }}>
                      <SectionSelection
                        sections={groupSections || []}
                        activeSection={activeSection}
                        changeActiveSection={changeActiveSection}
                      />
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                      <button
                        onClick={handleExportPDF}
                        className="btn add-btn btn-desktop"
                        disabled={!layout || layout.length === 0 || isExporting}
                      >
                        <DownloadIcon small />
                        Export to PDF
                      </button>
                    </div>
                  </div>
                </div>
                {isExporting && (
                  <DashboardExportComponent
                    id={uuid}
                    title={title}
                    handleExportPDF={handleExportPDFFalse}
                    publicLink={true}
                    dashboardId={activeSection.id}
                  />
                )}
                <GridLayoutComponent
                  widgets={widgets}
                  editLayout={false}
                  resizableDash={false}
                  layout={layout}
                  shareable={uuid}
                  isLoading={false}
                  dateChosen={dateChosen}
                  compactType={'vertical'}
                  pdf=""
                  dashboard={activeSection}
                  sectionError={sectionError ?? error}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DashboardContainer
