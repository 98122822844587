import React, { useCallback, useMemo, useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  createColumnHelper,
} from '@tanstack/react-table'
import { useTableVirtualization } from '@mm/ui/src/components/SharedTable/useTableVirtualization'
import { useSharedTableConfig } from '@mm/ui/src/components/SharedTable/useSharedTableConfig'
import { MAX_SIZE, MIN_SIZE } from '@mm/ui/src/components/SharedTable/utils'
import {
  flattenPowerTableRow,
  formatString,
} from '@mm/ui/src/components/Powertable/utils'
import { BREAKDOWNS_KEY } from '@mm/ui/src/components/Powertable/constants'
import { Table as TableComponent } from './Table'
import { TableProps } from './types'
import {
  countColumnsBeforeLastLevel,
  customSortingFn,
  getPowertableTotals,
} from './utils'

const Table = ({
  headings,
  rows,
  metrics,
  breakdowns,
  errors,
  editLayout,
  configurations,
  additionalConfiguration,
  updateTableSettings,
  totals,
  children,
}: TableProps) => {
  const columnHelper = createColumnHelper()
  const {
    sorting,
    handleSortingChange,
    columnResizeMode,
    columnSizing,
    setColumnSizing,
    columnSizingInfo,
    setColumnSizingInfo,
  } = useSharedTableConfig(configurations, updateTableSettings)

  const [firstColumnGroupValues, setFirstColumnGroupValues] = useState<
    Set<string>
  >(new Set())

  const isImageTemplate = additionalConfiguration?.template === 'image'
  const imageAdditionalData = additionalConfiguration?.imageAdditionalData
  const totalColumns = useMemo(
    () => countColumnsBeforeLastLevel(headings),
    [headings]
  )

  const generateColumns = useCallback(
    (headings, metric, startIndex = { current: 0 }) => {
      if (!headings?.length) {
        return columnHelper.accessor(`column-${metric.indexMetric}`, {
          header: metric.label,
          accessorFn: row => {
            const rowMetricValues = row.slice(firstColumnGroupValues.size)
            return rowMetricValues[metric.indexMetric]
          },
          footer: ({ header }) => {
            const index = header.index
            const totalRow = getPowertableTotals(
              totals,
              firstColumnGroupValues.size
            )
            return totalRow[index]
          },
          sortingFn: customSortingFn,
          enableResizing: !editLayout,
          meta: {
            metric: { label: metric.label, type: metric.type },
          },
        })
      }

      const columns = headings.map(item => {
        if (item.columns && item.columns.length > 0) {
          return columnHelper.group({
            id: `column-group-${metric.indexMetric}-${metric.label}`,
            header: item.name,
            columns: generateColumns(item.columns, metric, startIndex),
            meta: {
              className: 'column-group',
              metric: { label: metric.label, type: metric.type },
            },
            enableResizing: !editLayout,
          })
        } else {
          const currentIndex = startIndex.current
          startIndex.current += 1

          const groupColumnOffset = metric.indexMetric * totalColumns
          const indexRow = groupColumnOffset + currentIndex

          return columnHelper.group({
            header: item.name,
            columns: [
              columnHelper.accessor(
                `column-${metric.indexMetric}-${currentIndex}`,
                {
                  header: metric.label,
                  footer: ({ header }) => {
                    const index = header.index
                    const totalRow = getPowertableTotals(
                      totals,
                      firstColumnGroupValues.size
                    )
                    return totalRow[index]
                  },
                  accessorFn: row => {
                    const rowMetricValues = row.slice(
                      firstColumnGroupValues.size
                    )
                    return rowMetricValues[indexRow]
                  },
                  sortingFn: customSortingFn,
                  enableResizing: !editLayout,
                  meta: {
                    metric: { label: metric.label, type: metric.type },
                  },
                }
              ) as any,
            ],
            enableResizing: !editLayout,
            meta: {
              className: 'column-group',
            },
          })
        }
      })

      return columns
    },
    [columnHelper, editLayout, firstColumnGroupValues, totalColumns, totals]
  )

  const generateRowsHeader = useCallback(
    (data, level = 1) => {
      const firstColumnValues = new Set(firstColumnGroupValues)

      const rowsHeader = data.map(item => {
        if (item.type === 'breakdown' && item.values.length > 0) {
          const rows = generateRowsHeader(item.values, level + 1)
          firstColumnValues.add(item.key)
          return { value: item.name, subRow: rows }
        } else {
          firstColumnValues.add(item.key)
          return { value: item.name, rowValues: item.values.flat() }
        }
      })
      if (firstColumnGroupValues.size !== firstColumnValues.size) {
        setFirstColumnGroupValues(firstColumnValues)
      }
      return rowsHeader
    },

    [firstColumnGroupValues]
  )
  const columns = useMemo(() => {
    const createFirstColumnGroup = Array.from(
      firstColumnGroupValues,
      (columnValue, index) => {
        const breakdownLabel = breakdowns ? breakdowns[columnValue] : undefined
        const value =
          breakdownLabel ??
          BREAKDOWNS_KEY[columnValue] ??
          formatString(columnValue)
        return columnHelper.accessor(`first-column-${index}`, {
          header: value,
          accessorFn: (row: Row) => row[index],
          enableResizing: !editLayout,
          footer: index === 0 ? 'Total' : ' ',
        })
      }
    )
    const firstColumnGroup = headings.length
      ? [
          columnHelper.group({
            id: 'first-column-group',
            enableResizing: !editLayout,

            columns: Array.from(
              firstColumnGroupValues,
              (columnValue: string, index: number) => {
                const breakdownLabel = breakdowns
                  ? breakdowns[columnValue]
                  : undefined
                const value =
                  breakdownLabel ??
                  BREAKDOWNS_KEY[columnValue] ??
                  formatString(columnValue)
                const isLastColumn = index === firstColumnGroupValues.size - 1
                return columnHelper.accessor(`first-column-${index}`, {
                  header: value,
                  footer: index === 0 ? 'Total' : ' ',
                  accessorFn: (row: Row) => row[index],
                  enableResizing: !editLayout,
                  size: isImageTemplate && isLastColumn ? 350 : 150,
                })
              }
            ),
          }),
        ]
      : createFirstColumnGroup

    const metricColumns = metrics
      .map(({ label, type }, indexMetric) => {
        const metric = { label, type, indexMetric }
        return generateColumns(headings, metric)
      })
      .flat()

    return [...firstColumnGroup, ...metricColumns]
  }, [
    columnHelper,
    firstColumnGroupValues,
    isImageTemplate,
    editLayout,
    headings,
    metrics,
    breakdowns,
    generateColumns,
  ])

  const data = useMemo(() => {
    const flatten = flattenPowerTableRow(generateRowsHeader(rows))
    return flatten
  }, [rows, generateRowsHeader])

  const table = useReactTable({
    columns,
    data,
    defaultColumn: {
      minSize: MIN_SIZE,
      maxSize: MAX_SIZE,
    },
    columnResizeMode,
    state: {
      sorting,
      columnSizing,
      columnSizingInfo,
    },
    sortDescFirst: false,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: handleSortingChange,
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
  })

  const visibleColumns = table.getVisibleLeafColumns()

  const { tableWrapperRef, rowVirtualizer, virtualRows } =
    useTableVirtualization({
      rows: data,
      columns,
      visibleColumns,
      columnCount: visibleColumns.length,
      overscan: 20,
    })

  return (
    <TableComponent
      tableWrapperRef={tableWrapperRef}
      table={table}
      columns={columns}
      rowVirtualizer={rowVirtualizer}
      virtualRows={virtualRows}
      firstColumnGroupValues={firstColumnGroupValues}
      isImageTemplate={isImageTemplate}
      imageAdditionalData={imageAdditionalData}
      errors={errors}
      children={children}
      totals={totals}
    />
  )
}

export { Table }
