import React, { useMemo } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table'
import { useTableVirtualization } from './useTableVirtualization'
import { useSharedTableConfig } from './useSharedTableConfig'
import {
  customSortingFn,
  HeaderCell,
  MAX_SIZE,
  MIN_SIZE,
  renderEmptyState,
  renderErrorState,
  renderVirtualPadding,
} from './utils'
import {
  TableWrapper,
  Table,
  TableHeaderRow,
  TableBody,
  TableBodyRow,
  TableBodyHeaderCell,
  TableHead,
  FakeEmptyTh,
  FakeEmptyTd,
} from './styles'
import { Row, SharedTableProps } from './types'

const SharedTable = ({
  headings,
  rows,
  errors,
  editLayout,
  updateTableSettings,
  configurations,
  children,
  ...props
}: SharedTableProps) => {
  const {
    sorting,
    handleSortingChange,
    columnResizeMode,
    columnSizing,
    setColumnSizing,
    columnSizingInfo,
    setColumnSizingInfo,
  } = useSharedTableConfig(configurations, updateTableSettings)

  const columns = useMemo(
    () =>
      headings.map((heading, index) => ({
        id: `column-${index}`,
        header: heading,
        accessorFn: (row: Row) => row[index],
        size: 150,
        enableResizing: !editLayout,
        sortingFn: index === 0 ? 'alphanumeric' : customSortingFn,
      })),
    [headings, editLayout]
  )

  const data = useMemo(() => rows, [rows])

  const table = useReactTable({
    columns,
    data,
    defaultColumn: {
      minSize: MIN_SIZE,
      maxSize: MAX_SIZE,
    },
    columnResizeMode,
    state: {
      sorting,
      columnSizing,
      columnSizingInfo,
    },
    sortDescFirst: false,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: handleSortingChange,
    onColumnSizingChange: setColumnSizing,
    onColumnSizingInfoChange: setColumnSizingInfo,
  })

  const visibleColumns = table.getVisibleLeafColumns()

  const {
    tableWrapperRef,
    rowVirtualizer,
    virtualPaddingLeft,
    virtualPaddingRight,
    virtualRows,
    virtualColumns,
  } = useTableVirtualization({
    rows,
    columns,
    visibleColumns,
    columnEstimateSize: index => visibleColumns[index + 1]?.getSize(),
    columnCount: visibleColumns.length - 1,
  })

  const { rows: tableRows } = table.getRowModel()

  return (
    <TableWrapper
      ref={tableWrapperRef}
      style={{ direction: table.options.columnResizeDirection }}
    >
      <Table hover style={{ width: table.getCenterTotalSize() }}>
        <TableHead>
          {table?.getHeaderGroups().map(({ id, headers }) => {
            if (!headers.length) return null
            const {
              id: tableHeaderCellId,
              column,
              colSpan,
              getSize,
              getContext,
              getResizeHandler,
            } = headers[0]
            return (
              <TableHeaderRow key={id}>
                {renderVirtualPadding(virtualPaddingLeft, FakeEmptyTh)}
                <HeaderCell
                  key={tableHeaderCellId}
                  colSpan={colSpan}
                  column={column}
                  table={table}
                  isSticky
                  getSize={getSize}
                  getContext={getContext}
                  getResizeHandler={getResizeHandler}
                />
                {virtualColumns.map(virtualColumn => {
                  const virtualColumnIndex =
                    headers.length > 1
                      ? virtualColumn.index + 1
                      : virtualColumn.index
                  const {
                    id,
                    column,
                    colSpan,
                    getSize,
                    getContext,
                    getResizeHandler,
                  } = headers[virtualColumnIndex]

                  return (
                    <HeaderCell
                      key={id}
                      colSpan={colSpan}
                      column={column}
                      table={table}
                      getSize={getSize}
                      getContext={getContext}
                      getResizeHandler={getResizeHandler}
                    />
                  )
                })}
                {renderVirtualPadding(virtualPaddingRight, FakeEmptyTh)}
              </TableHeaderRow>
            )
          })}
        </TableHead>
        <TableBody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {virtualRows.map(virtualRow => {
            const row = tableRows[virtualRow.index]
            const visibleCells = row.getVisibleCells()
            const value = visibleCells[0].getValue() as string

            return (
              <TableBodyRow
                key={row.id}
                data-index={virtualRow.index}
                ref={node => rowVirtualizer.measureElement(node)}
                style={{ transform: `translateY(${virtualRow.start}px)` }}
                className="tbody"
              >
                <>
                  <TableBodyHeaderCell
                    key={visibleCells[0].id}
                    scope="row"
                    style={{
                      width: visibleCells[0].column.getSize(),
                      left: `${visibleCells[0].column.getStart('left')}px`,
                    }}
                  >
                    {flexRender(value, visibleCells[0].getContext())}
                  </TableBodyHeaderCell>
                  {renderVirtualPadding(virtualPaddingLeft, FakeEmptyTd)}
                  {virtualColumns.map(virtualColumn => {
                    const cell = visibleCells[virtualColumn.index + 1]
                    if (!cell) return null

                    const isLastCell =
                      visibleCells[visibleCells.length - 1].id === cell.id

                    return children({
                      cell,
                      virtualColumn,
                      virtualRow,
                      visibleCells,
                      sorting,
                      columnSizing,
                      isLastCell,
                      ...props,
                    })
                  })}
                  {renderVirtualPadding(virtualPaddingRight, FakeEmptyTd)}
                </>
              </TableBodyRow>
            )
          })}

          {!table?.getRowModel().rows.length &&
            !errors.length &&
            renderEmptyState(columns)}

          {renderErrorState(errors, columns.length)}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export { SharedTable }
