import { IconNames } from '@mm/ui/src/components/IconComponent/IconComponent'
import { DataSourcesIcon, metric } from './types'

export const tableIconSource = (metrics: metric[]) => {
  const sources: string[] = []
  if (!metrics) return sources
  for (const metric of metrics) {
    const sourceList = metric?.source ? metric.source.split(',') : []
    sourceList.forEach(source => {
      if (!sources.includes(source) && source !== null) {
        sources.push(source)
      }
    })
  }
  return sources
}

export const DATA_SOURCES_ICONS: DataSourcesIcon = {
  META: IconNames.MetaIcon,
  META_SOCIAL: IconNames.MetaIcon,
  GOOGLE: IconNames.GoogleAdsIcon,
  GOOGLE_ANALYTICS: IconNames.GoogleAnalyticsIcon,
  GA4: IconNames.GoogleAnalyticsIcon,
  LINKEDIN: IconNames.LinkedinIcon,
  INSTAGRAM_SOCIAL: IconNames.InstagramIcon,
  HUBSPOT: IconNames.HubspotIcon,
  CLOSE: IconNames.CloseIcon,
  TIKTOK: IconNames.TiktokIcon,
  'MASTER-METRICS': IconNames.MasterMetricsIcon,
  TIENDANUBE: IconNames.TiendaNubeIcon,
  GOOGLE_SHEETS: IconNames.GoogleSheetsIcon,
}
