/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  addHours,
  differenceInDays,
  endOfMonth,
  format,
  startOfMonth,
  sub,
} from 'date-fns'
import { getDateRanges } from './getDateRages'
import { DateTypeOptions, TableDate } from '../types'
import { DATE_FNS_FORMAT } from '../../SingleDatePicker/SingleDatePicker'

const dates = getDateRanges(0, 'yyyy-MM-dd')

const calculateCustomDate = (startDate: Date, endDate: Date, diff: number) => {
  return {
    from: addHours(startDate, diff),
    to: addHours(endDate, diff),
  }
}

const calculatePreviousPeriod = (
  startDate: Date,
  endDate: Date,
  diff: number
) => {
  const compareDate =
    endDate.getTime() > new Date().getTime() ? new Date() : endDate
  const daysDifference = differenceInDays(compareDate, startDate)
  return {
    from: addHours(sub(startDate, { days: daysDifference + 1 }), diff),
    to: addHours(sub(startDate, { days: 1 }), diff),
  }
}

export const calculateDate = (
  period: string,
  startDate: Date,
  endDate: Date,
  trimEndDate = true
) => {
  const diff = new Date().getTimezoneOffset() / 60
  if (period === 'Customize' || period === 'Last days') {
    return calculateCustomDate(startDate, endDate, diff)
  }
  if (period === 'Previous period') {
    return calculatePreviousPeriod(startDate, endDate, diff)
  }
  const calculatedDate = dates.find(
    existentDate => existentDate.name === period
  )!

  const finalDate = {
    from: addHours(new Date(calculatedDate.from!), diff),
    to: addHours(new Date(calculatedDate.to!), diff),
  }

  if (trimEndDate && finalDate.to.getTime() > new Date().getTime()) {
    finalDate.to = addHours(new Date(), diff)
  }
  return finalDate
}

export const getDefaultDateValues = (dateType?: string) => {
  if (dateType === 'isToday') return format(new Date(), DATE_FNS_FORMAT)
  if (dateType === 'firstDayOfMonth')
    return format(startOfMonth(new Date()), DATE_FNS_FORMAT)

  if (dateType === 'lastDayOfMonth')
    return format(endOfMonth(new Date()), DATE_FNS_FORMAT)

  return undefined
}

export const getFieldDate = (
  dateType: DateTypeOptions,
  tableDate: TableDate,
  defaultDate?: string
) => {
  const defaultDateValue = getDefaultDateValues(dateType)
  if (defaultDateValue) return defaultDateValue

  if (!['firstDayOverviewDate', 'lastDayOverviewDate'].includes(dateType))
    return defaultDate

  const updatedDate = calculateDate(
    tableDate.name!,
    new Date(tableDate.since),
    new Date(tableDate.until),
    false
  )

  const userTimezoneOffset = updatedDate.from.getTimezoneOffset() * 60000
  if (dateType === 'firstDayOverviewDate')
    return format(
      new Date(updatedDate.from.getTime() + userTimezoneOffset),
      DATE_FNS_FORMAT
    )
  if (dateType === 'lastDayOverviewDate')
    return format(
      new Date(updatedDate.to.getTime() + userTimezoneOffset),
      DATE_FNS_FORMAT
    )
}
