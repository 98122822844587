/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.User Validations
04.Menu
05.Error Page
06.Alerts
07.Dashboards
08.Onboarding
09.Overview
10.DataSources
11.AccountClients
12.Configure Integrations
13.Widget Error Messages
14.Custom Metrics
15.Google Sheet
16.Errors
*/

const language = {
  /* 01.General */
  'general.copyright': 'Gogo React © Todos los derechos reservados.',
  'general.and': 'y',
  'general.terms': 'terminos',
  'general.privacy-policy': 'política de privacidad',
  'general.accept': 'Aceptar',
  'general.by': 'por',
  'general.is': 'es',
  'general.free': 'gratis',
  'general.forever': 'para siempre',
  'general.skip': 'Saltear',
  'general.back': 'Atras',
  'general.confirm': 'Confirmar',
  'general.next': 'Siguiente',
  'general.cancel': 'Cancelar',
  'general.need-help': 'Necesitas ayuda?',
  'general.clear-sort': 'Limpiar orden',
  'general.view': 'Ver',
  'general.success': 'Éxito',
  'general.try-again': ' Intenta de nuevo',
  'general.edit': 'Editar',

  /* 02.Inicio de sesión de usuario, cierre de sesión, registro */
  'user.login-title': 'Iniciar sesión',
  'user.login-main-title':
    'La solución todo en uno para agencias y equipos de marketing',
  'user.register-looker-plan':
    'Ahora con integración de Looker Data Studio, Exporta a Sheets, configura alertas de control y visualiza tus datos de marketing sin esfuerzo.',
  'user.register': 'Registro',
  'user.email': 'Correo Electrónico',
  'user.company-email': 'Email Corporativo',
  'user.password': 'Contraseña',
  'user.confirm-password': 'Confirmar contraseña',
  'user.show-password': 'Mostrar contraseña',
  'user.hide-password': 'Ocultar contraseña',
  'user.forgot-password-title': 'Olvidé mi contraseña',
  'user.forgot-password-question': '¿Olvidaste tu contraseña?',
  'user.forgot-password-send-link': 'Restablecer la contraseña',
  'user.forgot-password-back-login': 'Volver al inicio de sesión',
  'user.forgot-password-create-account': 'Crear una nueva cuenta',
  'user.back-to-forgot-password': 'Volver a olvidé mi contraseña',
  'user.recover-password': 'Recuperar contraseña',
  'user.recover-password-save': 'Guardar contraseña',
  'user.fullname': 'Nombre completo',
  'user.login-button': 'Iniciar sesión',
  'user.register-button': 'Registrarse',
  'user.new-user': '¿Eres nuevo en Master Metrics?',
  'user.cannot-access-account': '¿No puedes ingresar a tu cuenta?',
  'user.privacy-policy': 'Política de Privacidad',
  'user.terms-service': 'Términos de Servicio',
  'user.terms-and': 'y',
  'user.reset-password-button': 'Reiniciar',
  'user.buy': 'COMPRAR',
  'user.username': 'Nombre de Usuario',
  'user.crm': ' Qué CRM usa actualmente?',
  'user.phone': 'Teléfono',
  'user.existent-account': '¿Ya tienes cuenta?',
  'user.mm-existent-account': '¿Ya tienes cuenta en Master Metrics?',
  'user.hubspot-register-title':
    'Tablero de control de Marketing para clientes de Hubspot',
  'user.hubspot-register-subtitle':
    'Calcula el costo por cada instancia del funnel por campaña o cuenta publicitaria en 3 clicks',
  'user.overview-sheet-register-title':
    'Supervise todos los datos de los clientes de su agencia en este tablero de control.',
  'user.overview-sheet-register-subtitle':
    '¿Necesita verificar 20 tableros de control diferentes para tener una visión completa de su agencia? Descárguelo gratis.',
  'user.forgot-password-error': 'Error de Olvido de Contraseña',
  'user.login-error': 'Error de Inicio de Sesión',
  'user.signup-error': 'Error de Registro',
  'user.forgot-password-success': 'Correo enviado con éxito',
  'user.recover-password-error': 'Error de Recuperar Contraseña',
  'user.recover-password-success': 'Recuperar Contraseña Exitoso',

  // 03.User Validations
  'validation.fullname': 'Solo se permiten caracteres alfabéticos',
  'validation.fullname-required': 'Nombre completo es requerido',
  'validation.email-invalid': 'Correo Electrónico inválido',
  'validation.email-required': 'Correo Electrónico es requerido',
  'validation.company-email':
    'Por favor, ingrese su correo electrónico corporativo',
  'validation.password-min': 'La contraseña debe tener al menos 6 caracteres',
  'validation.password-required': 'Contraseña es requerida',
  'validation.password-number': 'La contraseña debe incluir al menos 1 número',
  'validation.password-match': 'Las contraseñas deben coincidir',
  'validation.confirm-password-required': 'Confirmar contraseña es requerido',
  'validation.terms':
    'Se debe aceptar la Política de Privacidad y los Términos de Servicio',

  /* 03.Menú */
  'menu.app': 'Inicio',
  'menu.dashboards': 'Tableros',
  'menu.gogo': 'Gogo',
  'menu.start': 'Comienzo',
  'menu.second-menu': 'Segundo menú',
  'menu.second': 'Segundo',
  'menu.ui': 'IU',
  'menu.charts': 'Gráficos',
  'menu.chat': 'Chatea',
  'menu.survey': 'Encuesta',
  'menu.todo': 'Notas',
  'menu.search': 'Búsqueda',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  /* 04.Error  */
  'pages.error-title': 'Vaya, parece que ha ocurrido un error!',
  'pages.error-code': 'Código de error',
  'pages.go-back-home': 'REGRESAR A INICIO',
  'pages.error-boundary.title': '¡Ups! Algo salió mal',
  'pages.error-boundary.message':
    'Lamentamos el inconveniente. Por favor, intenta recargar la página y, si el problema persiste',
  'pages.error-boundary.message-request-assistance':
    'solicita asistencia inmediatamente',

  /* 05.AppSections */
  'app.alerts': 'Alertas',
  'app.dashboards': 'Dashboards',
  'app.overviews': 'Overview',

  /* 06.Alerts */
  'alerts.register-title': 'Configura alertas para tus campañas de marketing y',
  'alerts.register-title-highlight': 'Duerme tranquilo',
  'alerts.free-price': '¿Precio? No te preocupes',
  'alerts.main-title':
    'Recibe notificaciones cuando una métrica de campaña esté por debajo del rendimiento esperado.',

  /* 07.Dashboards */
  'dashboards.register-title-1': 'Crea',
  'dashboards.dashboards': 'tableros de control',
  'dashboards.register-title-2': 'para tus campañas de Marketing de ',
  'dashboards.register-title-highlight': 'forma 100% gratuita',
  'dashboards.free-price':
    'No sigas pagando para enviar tus datos de Facebook Ads a Looker Studio',
  'dashboards.main-title': 'Crea tu panel de marketing gratis',
  'dashboards.simpletable-error-loading-table': 'Error al cargar la tabla',
  'dashboards.simpletable-server-error':
    'El servidor no pudo procesar los datos de la tabla',
  'dashboards.no-metrics-selected-for-table':
    'No hay métricas seleccionadas para esta tabla',
  'dashboards.error-loading-dashboard-title': 'Error al cargar los paneles',
  'dashboards.error-loading-dashboard-message':
    'No se pudieron cargar los paneles, por favor intente nuevamente o contacte al soporte',
  'dashboards.powertable-duplicate-success-message':
    'Powertable se ha duplicado con éxito',
  'dashboards.widget-duplicate-success-message': 'se ha duplicado con éxito',

  /* 08.Onboarding */
  'onboarding.title': 'Configuración de cuenta',
  'onboarding.free-dashboards-description':
    'Al agregar tus fuentes de datos y cuentas, vamos a crear reportes para ti. Vas a tener todo creado en segundos sin ningún esfuerzo',
  'onboarding.description':
    'Al agregar tus fuentes de datos y cuentas, vamos a crear tablas overview, alertas y reportes para ti. Vas a tener todo creado en segundos sin ningún esfuerzo',
  'onboarding.source-selection': 'Seleccione la fuente de datos',
  'onboarding.connected-sources': 'Fuentes conectadas',
  'onboarding.loading-configuration':
    'Preparando los mejores paneles de control y reportes que has visto para tus clientes',
  'onboarding.modal-title': 'Saltear configuración de cuenta',
  'onboarding.modal-body':
    '¿Quieres arrancar con una cuenta Demo con data de prueba? o ¿Prefieres arrancar con una cuenta vacía, agregar tu data de las plataformas publicitarias, y luego crear manualmente los reportes?',
  'onboarding.modal-scratch-button': 'Arrancar de cero con mi data',
  'onboarding.modal-demo-button': 'Demo con data de prueba',
  'onboarding.end-demo': 'End Demo',
  'onboarding.end-demo-modal-text':
    'Estas seguro que deseas terminar la demo? Todos los datos demo serán eliminados asi puedes empezar a configurar tus propios datos',
  'onboarding.add-source-end-demo-modal-text':
    'Para conectar una fuente de datos, necesitas terminar la demo. Estas seguro que deseas terminar la demo? Todos los datos demo serán eliminados asi puedes empezar a configurar tus propios datos',
  'onboarding.end-demo-back-to-configuration':
    'Confirmar e ir a agregar cuenta',

  /* 09. Overview */
  'clients.configuration': 'Configuración de Clientes',
  'clients.add-client': 'Agregar cliente',
  'clients.remove-client': 'Eliminar cliente',
  'clients.remove-client-text':
    '¿Estas seguro que deseas eliminar este cliente?',
  'overview.register-title-1': 'Todas tus ',
  'overview.overviews': 'cuentas publicitarias',
  'overview.register-title-2': ' en una sola tabla',
  'overview.free-price':
    'Basta de entrar a cada dashboard para saber cómo vienen tus clientes. Presupuestos, objetivos y métricas de TODOS tus clientes en una SOLA TABLA.',
  'overview.call-to-action': 'Conocé Free Overview',
  'overviews.main-title': 'Crea tu tabla de visión general de marketing gratis',
  'overviews.create-view-title-message': 'Vista de creación',
  'overviews.create-view-message': 'Creando nueva vista',

  /* 10. DataSources */
  'datasource.add-source': 'Agregar fuente',
  'datasource.close-api-key-placeholder': 'Introduzca el apikey de Close',

  /* 11. AccountClients */
  'account-clients.add-new': 'Agregar cuenta',
  'account-clients.add-new-description':
    'Para agencias, introduzca el nombre de la cuenta del cliente. Para empresas, introduzca el nombre de su empresa.',
  'account-clients.schedule-a-call': 'Agenda una llamada con nuestro equipo',
  'account-clients.client-type-label': 'Tipo de negocio',
  'account-clients.client-name-label': 'Nombra tu cuenta',
  'account-clients.client-type-placeholder': 'Seleccione tipo de negocio',
  'account-clients.client-name-placeholder': 'Ejemplo: Cuenta Demo',
  'account-clients.client-account-placeholder': 'Seleccione cuentas',
  'account-clients.client-source-placeholder': 'Seleccione fuente',
  'account-clients.client-budget-placeholder': 'Ingrese el presupuesto mensual',
  'account-clients.client-conversion-metric-placeholder':
    'Seleccione la métrica clave de conversiones',
  'account-clients.client-account-label': 'Cuenta publicitaria',
  'account-clients.client-source-label': 'Fuente',
  'account-clients.client-budget-label': 'Presupuesto mensual',
  'account-clients.client-conversion-metric-label':
    'Métrica clave de conversión',
  'account-clients.first-source-connection':
    'Parece que es la primera vez que utilizas esta fuente de datos, se necesitan permisos para poder obtener información de esta fuente de datos',
  'account-clients.client-type-error':
    'Por favor selecciona un tipo de negocio',
  'account-clients.account-name-error':
    'Por favor introduce un nombre de cuenta',

  /* 12. Configure Integrations */
  'configure-integration.title': 'configurando source',
  'configure-integration.description':
    'Aguarde un momento mientras terminamos de configurar los ultimos detalles de su nuevo origen de datos.',

  /* 13. Widget Error Messages */
  'token-revalidate-alert.message':
    'Se ha producido un problema con {source}. Para restablecerlo.',
  'token-revalidate-alert.link': 'Haga clic aquí para volver a conectarse',
  'token-revalidate-missing-permissions.message':
    'Se han perdido los permisos de la cuenta publicitaria "{account}" del origen "{source}". Verifíquelos o elimine la cuenta.',
  'token-revalidate-missing-permissions-button.message': 'Ir a Datasources',
  'token-revalidate-error.message':
    'Tu conexión a "{source}" necesita ser reconectada, por favor has clic en el siguiente botón.',
  'token-revalidate-button.message': 'Reconectar',
  'widget-no-data-was-found.message': 'No se encontró dato para este periodo.',
  'widget-no-data-was-found-button.message': 'Editar Widget',
  'widget-default-error.message':
    'Ocurrió un error inesperado, por favor reintente más tarde.',
  'widget-meta-social-is-required.message':
    'Para utilizar estas metricas debe agregar el datasource facebook social',
  'widget-instagram-social-is-required.message':
    'Para utilizar estas metricas debe agregar el datasource instagram social',
  'widget-meta-social-no-data-on-facebook-ads.message':
    'No hay publicaciones sociales pagadas en el periodo seleccionado',
  'widget-instagram-no-data-on-facebook-ads.message':
    'No hay publicaciones pagados de Instagram en el periodo seleccionado',
  'widget-meta-user-without-meta-social.message':
    'Para utilizar estas metricas debe agregar el datasource facebook social',
  'widget-meta-user-without-instagram-social.message':
    'Para utilizar estas metricas debe agregar el datasource instagram social',
  'widget-meta-user-without-facebook-ads.message':
    'Para utilizar estas metricas debe agregar el datasource Facebook Ads',

  /* 15. Google Sheet */
  'modal.selectGoogleSheet.title': 'Seleccionar Google Sheet',
  'modal.selectGoogleSheet.sheet': 'Hoja',
  'modal.selectGoogleSheet.sheetDetails': 'Detalles de la Hoja',
  'modal.selectGoogleSheet.useFirstRowAsHeaders':
    'Usar primera fila como encabezados',
  'modal.selectGoogleSheet.tableRangeViewChecked': 'Incluir rango específico',
  'modal.selectGoogleSheet.specificRangePlaceholder': 'Ejemplo A1:B50',
  'modal.selectGoogleSheet.continue': 'Continuar',
  'modal.selectGoogleSheet.options': 'Opciones',
  'modal.selectGoogleSheet.invalidRange':
    'Formato de rango inválido. Use "A1:Z999"',
  'modal.selectGoogleSheet.selectSheet': 'Por favor seleccione una hoja',
  'modal.selectGoogleSheet.selectSheetView':
    'Por favor seleccione una vista de hoja',
  'modal.selectGoogleSheet.error':
    'Ocurrió un error al seleccionar la hoja de Google. Por favor, inténtelo de nuevo.',
  'modal.selectGoogleSheet.emptySheet':
    'La hoja o rango seleccionada no tiene datos',
  'modal.selectGoogleSheet.apply': 'Aplicar',
  'modal.selectGoogleSheet.reconnect': 'Reconectar',
  'modal.selectGoogleSheet.reconnect-title': '¿Aplicar cambios de conexión?',
  'formSpreadsheetReconnect.configuration': 'Configuración',
  'formSpreadsheetReconnect.noConfigChanges':
    'Ningún cambio en la configuración',
  'formSpreadsheetReconnect.fields': 'Campos',
  'formSpreadsheetReconnect.missingFields': 'Falta información en campos:',
  'formSpreadsheetReconnect.allFieldsComplete':
    'Todos los campos están completos',
  'formSpreadsheetReconnect.modifiedFields': 'Campos modificados:',
  'formSpreadsheetReconnect.noModifiedFields': 'No hay campos modificados',
  'formSpreadsheetReconnect.newFields': 'Campos nuevos:',
  'formSpreadsheetReconnect.noNewFields': 'No hay campos nuevos',
  'formSpreadsheetReconnect.existChanges':
    'La configuracion de la hoja ha cambiado o son incorrectos. Por favor, actualice la configuración',
  'widget-default-error.goto': 'Ir a',
  'modal.selectGoogleSheet.helpLinkText':
    'Cómo agregar un archivo de Google Sheets',

  /* 14.Custom Metrics */
  'custommetrics-calculated-metrics': 'Métricas Calculadas',
  'custommetrics-name': 'Nombre',
  'custommetrics-display-name': 'Nombre para Mostrar',
  'custommetrics-add-metric-display-name': 'Agregar nombre para mostrar',
  'custommetrics-type-formula':
    'Escriba una fórmula / use @ para agregar métrica',
  'custommetrics-eg-metric': 'Ej.: "[Nombre de Métrica 1] / 2"',
  'custommetrics-decimals': 'Decimales',
  'custommetrics-choose-decimals': 'Elija la cantidad de decimales',
  'custommetrics-format': 'Formato',
  'custommetrics-choose-result-format': 'Elija el formato del resultado',
  'custommetrics-eg-would-display': 'Ej.: 0.54357 se mostraría como',
  'custommetrics-cancel': 'Cancelar',
  'custommetrics-save': 'Guardar',
  'custommetrics-+add': '+ Agregar',
  'custommetrics-update': 'Actualizar',
  'custommetrics-add': 'Agregar',
  'custommetrics-query-metric': 'Métrica de Consulta',
  'custommetrics-add-name': 'Agregar un nombre',
  'custommetrics-source': 'Fuente',
  'custommetrics-choose-source': 'Elija la fuente',
  'custommetrics-error': '¡Error!',
  'custommetrics-add-metric': 'Agregar Métrica',
  'custommetrics-query-metrics': 'Métricas de Consulta',
  'custommetrics-search-by-name': 'Buscar por nombre',
  'custommetrics-add-query-metrics': 'Agregar métricas de consulta',
  'custommetrics-no-calculated-metric': 'No tienes ninguna métrica calculada',
  'custommetrics-click-to-add-one':
    'Haz clic en el botón de abajo para agregar una',
  'custommetrics-add-calculated-metric': 'Agregar métrica calculada',
  'custommetrics-delete-this': '¿Estás seguro de que deseas eliminar esto?',
  'custommetrics-metric': 'Métrica',
  'custommetrics-add-metric-name': 'Agregar nombre de métrica',
  'custommetrics-edit': 'Editar',
  'custommetrics-add-query-metric': 'Agregar Métrica de Consulta',
  'custommetrics-displayname-helptooltip':
    'Este nombre puede ser incluido en widgets del tablero como tablas y gráficos. Si no se incluye una entrada, el nombre para mostrar será el nombre de la métrica personalizada',

  /** 16.Errors */
  'errors.ad-accounts-limit-exceeded':
    'Límite de cuentas publicitarias superado. Para añadir una más necesita un Plan Personalizado. Contacte con Soporte',
  'errors.datasource-limit-exceeded-long':
    'Límite de fuentes de datos superado. Para añadir una más necesita un Plan Personalizado. Contacte con Soporte',
  'errors.datasource-limit-exceeded': 'Límite de fuentes de datos superado.',
  'errors.payment-requirement': 'Pago requerido',
  'errors.payment_required': 'Pago requerido',
}

export default language
