/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.User Validations
04.Menu
05.Error Page
06.Alerts
07.Dashboards
08.Onboarding
09.Overview
10.DataSources
11.AccountClients
12.Configure Integrations
13.Widget Error Messages
14.Custom Metrics
15.Google Sheet
16.Errors
*/
const language = {
  /* 01.General */
  'general.copyright': 'Gogo React © 2018 All Rights Reserved.',
  'general.and': 'and',
  'general.terms': 'terms',
  'general.privacy-policy': 'privacy policy',
  'general.accept': 'Accept',
  'general.by': 'by',
  'general.is': 'is',
  'general.free': 'free',
  'general.forever': 'forever',
  'general.skip': 'Skip',
  'general.back': 'Back',
  'general.confirm': 'Confirm',
  'general.next': 'Next',
  'general.cancel': 'Cancel',
  'general.need-help': 'Need help?',
  'general.clear-sort': 'Clear sort',
  'general.view': 'View',
  'general.success': 'Success',
  'general.try-again': 'Try again',
  'general.edit': 'Edit',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Log in',
  'user.login-main-title':
    'The all-in-one solution for agencies and marketing teams',
  'user.register-looker-plan':
    'Now with Looker Data Studio integration, Export to Sheets, set control alerts, and visualize your marketing data effortlessly.',
  'user.register': 'Sign Up',
  'user.email': 'Email',
  'user.company-email': 'Company Email',
  'user.password': 'Password',
  'user.confirm-password': 'Confirm password',
  'user.show-password': 'Show password',
  'user.hide-password': 'Hide password',
  'user.forgot-password-title': 'Forgot Password',
  'user.forgot-password-question': 'Forgot password?',
  'user.forgot-password-send-link': 'Reset password',
  'user.forgot-password-back-login': 'Back to login',
  'user.forgot-password-create-account': 'Create new account',
  'user.back-to-forgot-password': 'Back to forgot password',
  'user.recover-password': 'Recover Password',
  'user.recover-password-save': 'Save Password',
  'user.fullname': 'Full name',
  'user.login-button': 'Log in',
  'user.register-button': 'Sign Up',
  'user.new-user': 'Are you new to Master Metrics?',
  'user.cannot-access-account': "Can't access your account?",
  'user.privacy-policy': 'Privacy Policy',
  'user.terms-service': 'Terms of Service',
  'user.terms-and': '&',

  'user.reset-password-button': 'Reset',
  'user.buy': 'BUY',
  'user.username': 'Username',
  'user.api': 'Close API key',
  'user.phone': 'Phone',
  'user.crm': 'What CRM do you use?',
  'user.existent-account': 'Already have an account?',
  'user.mm-existent-account': 'Already have a Master Metrics account?',
  'user.hubspot-register-title': 'Marketing Dashboards for Hubspot customers',
  'user.hubspot-register-subtitle':
    'Calculate the cost per each funnel stage splitted by ad campaign or ad account in 3 clicks',
  'user.overview-sheet-register-title':
    "Supervise all your agency's client data on this dashboard.",
  'user.overview-sheet-register-subtitle':
    'Do you need to check 20 different dashboards to get a complete view of your agency? Download it for free.',
  'user.forgot-password-error': 'Forgot Password Error',
  'user.login-error': 'Login Error',
  'user.signup-error': 'Registration Error',
  'user.forgot-password-success': 'Email sent successfully',
  'user.recover-password-error': 'Password Recovery Error',
  'user.recover-password-success': 'Password Recovery Successful',

  // 03.User Validations
  'validation.fullname': 'Only alphabetic characters are allowed',
  'validation.fullname-required': 'Fullname is required',
  'validation.email-invalid': 'Invalid Email address',
  'validation.email-required': 'Email is required',
  'validation.company-email': 'Please enter your business email address',
  'validation.password-min': 'Password must be at least 6 characters long',
  'validation.password-required': 'Password is required',
  'validation.password-number': 'Password must include at least 1 number',
  'validation.password-match': 'Passwords must match',
  'validation.confirm-password-required': 'Confirm password is required',
  'validation.terms': 'Privacy Policy & Terms of Service must be accepted',

  /* 04.Menu */
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Gogo',
  'menu.start': 'Start',
  'menu.second-menu': 'Second Menu',
  'menu.second': 'Second',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.docs': 'Docs',
  'menu.blank-page': 'Blank Page',
  'menu.metrics': 'Metrics',
  'menu.dashboard': 'Dashboard',
  'menu.add-dashboard': '+ Add Dashboard',

  'modal.basic': 'modal basic',

  'modal.launch-demo-modal': 'demo modal',

  'modal.modal-title': 'modal title',

  /* 05.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',
  'pages.error-boundary.title': 'Oops! Something went wrong',
  'pages.error-boundary.message':
    'We’re sorry for the inconvenience. Please try refreshing the page, and if the issue persists',
  'pages.error-boundary.message-request-assistance':
    'request assistance immediately',

  /* 05.AppSections */
  'app.alerts': 'Alerts',
  'app.dashboards': 'Dashboards',
  'app.overviews': 'Overview',

  /* 06.Alerts */
  'alerts.register-title':
    'Set up alerts to control underperforming campaigns and',
  'alerts.register-title-highlight': 'Sleep peacefully',
  'alerts.free-price': "Price? Don't worry",
  'alerts.main-title':
    'Receive notifications when a campaign metric is underperforming',

  /* 07.Dashboards */
  'dashboards.register-title-1': 'Create',
  'dashboards.dashboards': 'dashboards',
  'dashboards.register-title-2': 'for all your Marketing data',

  'dashboards.register-title-highlight': '100% free',
  'dashboards.free-price':
    'Stop paying to send your Facebook Ads data to Looker Studio',
  'dashboards.main-title': 'Create your marketing dashboard for free',
  'dashboards.simpletable-error-loading-table': 'Error loading table',
  'dashboards.simpletable-server-error': 'Server could not process table data',
  'dashboards.no-metrics-selected-for-table':
    'There are no metrics selected for this table',
  'dashboards.error-loading-dashboard-title': 'Error loading dashboards',
  'dashboards.error-loading-dashboard-message':
    'Failed to load dashboards, please try again or contact support',
  'dashboards.powertable-duplicate-success-message':
    'Powertable has been successfully duplicated',
  'dashboards.widget-duplicate-success-message':
    'has been successfully duplicated',

  /* 08.Onboarding */
  'onboarding.title': 'Account configuration',
  'onboarding.free-dashboards-description':
    'By adding your data sources and accounts, we will create dashboards for you. You will have everything created in seconds without any effort',
  'onboarding.description':
    'By adding your data sources and accounts, we will create overview tables, alerts and dashboards for you. You will have everything created in seconds without any effort',
  'onboarding.source-selection': 'Select source to connect',
  'onboarding.connected-sources': 'Connected sources',
  'onboarding.loading-configuration':
    'Preparing the best reports you have ever seen for your clients.',
  'onboarding.modal-title': 'Skip Account configuration',
  'onboarding.modal-body':
    'Do you want to start with a Demo account with dummy data? or do you want to start with an empty account, add your data sources, and start creating reports manually?',
  'onboarding.modal-scratch-button': 'Start from scratch with my data',
  'onboarding.modal-demo-button': 'Demo with dummy data',
  'onboarding.end-demo': 'End Demo',
  'onboarding.end-demo-modal-text':
    'Are you sure you want to end the demo? All demo data will be removed so you can start from scratch',
  'onboarding.add-source-end-demo-modal-text':
    'To connect your data source, you need to finish the demo. Are you sure you want to end the demo? All demo data will be removed so you can start from scratch',
  'onboarding.end-demo-back-to-configuration':
    'Confirm and go to account configuration',

  /* 09. Overview */
  'clients.configuration': 'Clients Configuration',
  'clients.add-client': 'Add client',
  'clients.remove-client': 'Delete client',
  'clients.remove-client-text': 'Are you sure you want to delete this client?',
  'overview.register-title-1': 'All your ',
  'overview.overviews': 'Ad Accounts',
  'overview.register-title-2': 'in one single view',
  'overview.free-price':
    "How are your client's campaigns performing? Or do you need to check 20 dashboards every day just to get the FULL PICTURE?",
  'overview.call-to-action': 'Try Free Overview',
  'overviews.main-title': 'Create your marketing overview table for free',
  'overviews.create-view-title-message': 'View creation',
  'overviews.create-view-message': 'Creating new View',

  /* 10. DataSources */
  'datasource.add-source': 'Add data source',
  'datasource.close-api-key-placeholder': 'Insert Close api key',

  /* 11. AccountClients */
  'account-clients.add-new': 'Add an account',
  'account-clients.add-new-description':
    'For agencies, enter the name of the client account. For companies, enter the name of your business',
  'account-clients.schedule-a-call': 'Schedule a call with our team',
  'account-clients.client-type-label': 'Business Type',
  'account-clients.client-name-label': 'Name your Account',
  'account-clients.client-type-placeholder': 'Select Business Type',
  'account-clients.client-name-placeholder': 'Example: Demo Account',
  'account-clients.client-account-placeholder': 'Select Account',
  'account-clients.client-source-placeholder': 'Select Source',
  'account-clients.client-budget-placeholder': 'Insert Account monthly budget',
  'account-clients.client-conversion-metric-placeholder':
    'Select key conversion metric',
  'account-clients.client-account-label': 'Ad Account',
  'account-clients.client-source-label': 'Source',
  'account-clients.client-budget-label': 'Monthly budget',
  'account-clients.client-conversion-metric-label': 'Key conversion metric',
  'account-clients.first-source-connection':
    'It looks that it is the first time you use this source, permission is required to fecth source data',
  'account-clients.client-type-error': 'Please select a client type',
  'account-clients.account-name-error': 'Please introduce an account name',

  /* 12. Configure Integrations */
  'configure-integration.title': 'Configuring source',
  'configure-integration.description':
    'Please wait a moment while we finish configuring the last details of your new data source.',

  /* 13. Widget Error Messages */
  'token-revalidate-alert.message':
    'A problem has occurred with {source}. To re-establish it',
  'token-revalidate-alert.link': 'click here to reconnect',
  'token-revalidate-missing-permissions.message':
    'Permissions for ad account "{account}" from the source "{source}" have been lost. Please verify them or remove the account',
  'token-revalidate-missing-permissions-button.message': 'Go to Datasources',
  'token-revalidate-error.message':
    'Your connection to "{source}" needs to be reconnected',
  'token-revalidate-button.message': 'Reconnect',
  'widget-no-data-was-found.message': 'No data was found for this period',
  'widget-default-error.message':
    'An unexpected error occurred, please try again later',
  'widget-no-data-was-found-button.message': 'Edit Widget',
  'widget-meta-social-is-required.message':
    'To use these metrics you must add the facebook social datasource',
  'widget-instagram-social-is-required.message':
    'To use these metrics you must add the instagram social datasource',
  'widget-meta-social-no-data-on-facebook-ads.message':
    'There are no paid social posts in the selected period',
  'widget-instagram-no-data-on-facebook-ads.message':
    'There are no paid instagram media in the selected period',
  'widget-meta-user-without-meta-social.message':
    'To use these metrics you must add the datasource meta social',
  'widget-meta-user-without-instagram-social.message':
    'To use these metrics you must add the datasource instagram social',
  'widget-meta-user-without-facebook-ads.message':
    'To use these metrics, you must add the Facebook Ads datasource',

  /* 15. Google Sheet */
  'modal.selectGoogleSheet.title': 'Select Google Sheet',
  'modal.selectGoogleSheet.sheet': 'Sheet',
  'modal.selectGoogleSheet.sheetDetails': 'Sheet Details',
  'modal.selectGoogleSheet.useFirstRowAsHeaders': 'Use first row as headers',
  'modal.selectGoogleSheet.tableRangeViewChecked': 'Include specific range',
  'modal.selectGoogleSheet.specificRangePlaceholder': 'Example A1:B50',
  'modal.selectGoogleSheet.continue': 'Continue',
  'modal.selectGoogleSheet.options': 'Options',
  'modal.selectGoogleSheet.invalidRange': 'Invalid range format. Use "A1:Z999"',
  'modal.selectGoogleSheet.selectSheet': 'Please select a sheet',
  'modal.selectGoogleSheet.selectSheetView': 'Please select a sheet view',
  'modal.selectGoogleSheet.error':
    'An error occurred while selecting the Google Sheet. Please try again.',
  'modal.selectGoogleSheet.emptySheet':
    'The selected sheet or range has no data',
  'modal.selectGoogleSheet.apply': 'Apply',
  'modal.selectGoogleSheet.reconnect': 'Reconnect',
  'modal.selectGoogleSheet.reconnect-title': 'Apply connection changes?',
  'formSpreadsheetReconnect.configuration': 'Configuration',
  'formSpreadsheetReconnect.noConfigChanges': 'No changes in configuration',
  'formSpreadsheetReconnect.fields': 'Fields',
  'formSpreadsheetReconnect.missingFields': 'Missing information in fields:',
  'formSpreadsheetReconnect.allFieldsComplete': 'All fields are complete',
  'formSpreadsheetReconnect.modifiedFields': 'Modified fields:',
  'formSpreadsheetReconnect.noModifiedFields': 'No modified fields',
  'formSpreadsheetReconnect.newFields': 'New fields:',
  'formSpreadsheetReconnect.noNewFields': 'No new fields',
  'formSpreadsheetReconnect.existChanges':
    'The sheet settings have changed or are incorrect. Please update the configuration',
  'widget-default-error.goto': 'Go to',
  'modal.selectGoogleSheet.helpLinkText': 'How add Google Sheets file',

  /* 14.Custom Metrics */

  'custommetrics-calculated-metrics': 'Calculated Metrics',
  'custommetrics-name': 'Name',
  'custommetrics-display-name': 'Display Name',
  'custommetrics-add-metric-display-name': 'Add metric display name',
  'custommetrics-type-formula': 'Type a formula / use @ to add metric',
  'custommetrics-eg-metric': 'E.g. "[Metric 1 name] / 2"',
  'custommetrics-decimals': 'Decimals',
  'custommetrics-choose-decimals': 'Choose decimals amount',
  'custommetrics-format': 'Format',
  'custommetrics-choose-result-format': 'Choose result format',
  'custommetrics-eg-would-display': 'E.g.: 0.54357 would display as',
  'custommetrics-cancel': 'Cancel',
  'custommetrics-save': 'Save',
  'custommetrics-+add': '+ Add',
  'custommetrics-update': 'Update',
  'custommetrics-add': 'Add',
  'custommetrics-query-metric': 'Query Metric',
  'custommetrics-add-name': 'Add a name',
  'custommetrics-source': 'Source',
  'custommetrics-choose-source': 'Choose source',
  'custommetrics-error': 'Error!',
  'custommetrics-add-metric': 'Add Metric',
  'custommetrics-query-metrics': 'Query Metrics',
  'custommetrics-search-by-name': 'Search by name',
  'custommetrics-add-query-metrics': 'Add query metrics',
  'custommetrics-no-calculated-metric': "You don't have any calculated metric",
  'custommetrics-click-to-add-one': 'Click the button below to add one',
  'custommetrics-add-calculated-metric': 'Add Calculated metric',
  'custommetrics-delete-this': 'Are you sure you want to delete this',
  'custommetrics-metric': 'Metric',
  'custommetrics-add-metric-name': 'Add metric name',
  'custommetrics-edit': 'Edit',
  'custommetrics-add-query-metric': 'Add Query Metric',
  'custommetrics-displayname-helptooltip':
    'This name might be included in dashboard widgets such as tables and charts. In case that no input is included, Display name will be assigned the custom metric name',

  /** 16.Errors */
  'errors.ad-accounts-limit-exceeded':
    'Ad Accounts Limit Exceeded. To add one more you need a Custom Plan. Contact Support',
  'errors.datasource-limit-exceeded-long':
    'Data Sources Limit Exceeded. To add one more you need a Custom Plan. Contact Support',
  'errors.datasource-limit-exceeded': 'Data Sources Limit Exceeded.',
  'errors.payment-requirement': 'Payment Required',
}

export default language
