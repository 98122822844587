import React, { useMemo } from 'react'
import { flexRender } from '@tanstack/react-table'
import { WidgetWrapper } from '@mm/ui/src/components/WidgetWrapper'
import { PowerAIDescription } from '@mm/ui/src/components/PowerAIDescription/PowerAIDescription'
import { Table } from './Table'
import { TableBodyRowCell } from './Table/styles'
import { formatMetric } from './utils'
import { StyledCardFooter } from './style'
import { PowertableComponentProps } from './types'

const VITE_AI_DESCRIPTION_ENABLED =
  (import.meta as any).env.VITE_AI_DESCRIPTION_ENABLED === 'true'

const Powertable = ({
  dateLabel,
  widgetId,
  isLoading,
  loading,
  isPublic,
  fetchError,
  dateChosen,
  response,
  editLayout,
  updatePowerTableSettings,
  onEdit,
  onDuplicate,
  onDelete,
}: PowertableComponentProps) => {
  const aiDescription = useMemo(
    () => response?.data?.aiDescription?.message,
    [response]
  )
  const aiError = useMemo(
    () => response?.data?.aiDescription?.error,
    [response]
  )

  return (
    <WidgetWrapper
      widgetId={widgetId}
      componentId="powertable"
      title={response?.name}
      dateLabel={dateLabel}
      showIcons={response?.show_icons}
      iconsSource={[response?.source]}
      onDelete={onDelete}
      onEdit={onEdit}
      onDuplicate={onDuplicate}
      isLoading={isLoading || loading}
      errorsData={fetchError}
      isPublic={isPublic}
      dateChosen={dateChosen}
    >
      <Table
        headings={response?.data?.columns || []}
        rows={response?.data?.rowData || []}
        metrics={response?.data?.metrics || []}
        breakdowns={response?.data?.columnNames || {}}
        errors={[]}
        editLayout={editLayout}
        configurations={response?.configurations}
        additionalConfiguration={response?.data?.additionalConfiguration || {}}
        totals={response?.data?.total || {}}
        updateTableSettings={updatePowerTableSettings}
      >
        {({ cell }) => {
          const cellValue = cell.getValue()
          const cellMetricType = cell.column.columnDef?.meta?.metric?.type
          const format = response?.data?.additionalConfiguration?.format
          const cellFormat = format?.[cellMetricType]
          const value = formatMetric(
            cellFormat?.type ?? cellMetricType,
            cellValue,
            cellFormat?.decimals
          )

          return (
            <TableBodyRowCell
              key={cell.id}
              style={{ width: cell.column.getSize() }}
            >
              <span>{flexRender(value, cell.getContext())}</span>
            </TableBodyRowCell>
          )
        }}
      </Table>
      {VITE_AI_DESCRIPTION_ENABLED && (isLoading || loading) ? null : (
        <StyledCardFooter>
          <PowerAIDescription aiDescription={aiDescription} error={aiError} />
        </StyledCardFooter>
      )}
    </WidgetWrapper>
  )
}

export { Powertable }
