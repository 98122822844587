import { DecimalsByMetric } from './types'

export const percentageMetrics = [
  'ctr',
  'active_view_ctr',
  'inline_link_click_ctr',
  'outbound_clicks_ctr',
  'unique_link_clicks_ctr',
  'unique_outbound_clicks_ctr',
  'unique_ctr',
  'crashFreeUsersRate',
  'cartToViewRate',
  'bounceRate',
  'dauPerMau',
  'dauPerWau',
  'engagementRate',
  'firstTimePurchaserConversionRate',
  'firstTimePurchasersPerNewUser',
  'itemListClickThroughRate',
  'itemPromotionClickThroughRate',
  'wauPerMau',
  'userConversionRate',
  'sessionKeyEventRate',
  'userKeyEventRate',
  'sessionConversionRate',
  'conversion_rate',
  'linkedin_ctr',
  'linkedin_conversion_rate',
  'linkedin_ctr_outbound_click',
  'linkedin_conversions_from_interactions_rate',
  'optimizationScore',
  'percent_new_visitors',
  'active_view_viewability',
  'active_view_measurability',
  'top_impression_percentage',
  'absolute_top_impression_percentage',
  'campaign.optimization_score',
  'tn_productOrders_percentage_of_sales',
  'search_absolute_top_impression_share',
  'search_budget_lost_absolute_top_impression_share',
  'search_budget_lost_impression_share',
  'search_budget_lost_top_impression_share',
  'search_click_share',
  'search_exact_match_impression_share',
  'search_impression_share',
  'search_rank_lost_absolute_top_impression_share',
  'search_rank_lost_impression_share',
  'search_rank_lost_top_impression_share',
  'search_top_impression_share',
]

export const monetaryMetrics = [
  'cpm',
  'cpc',
  'cost_per_inline_link_click',
  'cpc_link_clicks',
  'cpc_outbound_click',
  'spend',
  'cost_per_unique_outbound_click',
  'cost_per_unique_click',
  'cost_per_unique_inline_link_click',
  'cpp',
  'average_cpm',
  'average_cpc',
  'average_cpe',
  'average_cpv',
  'average_cost',
  'cost_per_all_conversions',
  'cost_per_conversion',
  'active_view_measurable_cost_micros',
  'sum_deals',
  'avg_deals',
  'cost_micros',
  'advertiserAdCost',
  'advertiserAdCostPerClick',
  'advertiserAdCostPerConversion',
  'averagePurchaseRevenue',
  'averagePurchaseRevenuePerPayingUser',
  'averagePurchaseRevenuePerUser',
  'averageRevenuePerUser',
  'itemRevenue',
  'totalRevenue',
  'totalAdRevenue',
  'linkedin_cpc',
  'linkedin_cost_per_conversion',
  'linkedin_return_over_ad_spent',
  'taxAmount',
  'linkedin_average_cpm',
  'costInUsd',
  'costInLocalCurrency',
  'linkedin_cpc_outbound_click',
  'conversions_value',
  'conversion_offsite_conversion.fb_pixel_purchase_value',
  'conversion_omni_purchase_value',
  'current_model_attributed_conversions_value',
  'shippingAmount',
  'grossPurchaseRevenue',
  'grossItemRevenue',
  'billed_cost',
  'cash_spend',
  'tn_orders_total_billing',
  'instagram_media_paid_spend',
  'total_complete_payment_rate',
]

export const timeMetrics = [
  'video_avg_time_watched_actions',
  'averageSessionDuration',
  'userEngagementDuration',
]

export const timeMetrics_ms = [
  'post_video_avg_time_watched',
  'post_video_view_time',
]

export const numberMetrics = [
  'page_views_logged_in_total',
  'page_views_by_profile_tab_total',
  'page_cta_clicks_logged_in_total',
  'post_cta_clicks_total',
  'page_places_checkin_total',
  'page_views_total',
  'page_views_logged_in_total',
  'post_reactions_by_type_total',
  'post_reactions_like_total',
  'post_reactions_love_total',
  'post_reactions_wow_total',
  'post_reactions_haha_total',
  'post_reactions_sorry_total',
  'post_reactions_anger_total',
  'post_reactions_pride_total',
  'post_reactions_thankful_total',
  'video_reactions_like_total',
  'video_reactions_love_total',
  'video_reactions_wow_total',
  'video_reactions_haha_total',
  'video_reactions_sorry_total',
  'video_reactions_anger_total',
]

export const DefaultDecimalsByMetric: DecimalsByMetric = {
  frequency: 2,
  cpm: 2,
  cpc: 2,
  cpp: 2,
  linkedin_cpc: 2,
  linkedin_average_cpm: 2,
  cpc_outbound_click: 2,
  cost_per_unique_inline_link_click: 2,
  cost_per_unique_click: 2,
  cost_per_unique_outbound_click: 2,
  cost_per_1000_reached: 2,
  cost_per_result: 2,
  cost_per_all_conversions: 2,
  cost_per_conversion: 2,
  linkedin_cost_per_conversion: 2,
  linkedin_cost_per_conversion_one_click_leads: 2,
  linkedin_cost_per_conversion_sends: 2,
  linkedin_cost_per_conversion_opens: 2,
}

export const BREAKDOWNS_KEY: { [key: string]: string } = {
  account_name: 'Ad Account',
  ad_title: 'Ad Title',
  ad_status: 'Ad status',
  ad_url: 'Ad URL',
  ad_body: 'Ad Body',
  ad_creative_name: 'Ad Creative Name',
  ad_id: 'Ad',
  ad_image: 'Ad Image',
  ad_image_title: 'Ad Image and Title',
  ad_image_title_description: 'Ad Image, Title and Description',
  ad_cta_type: 'CTA Type',
  organic_instagram_social_fanpage: 'Instagram Profile',
  organic_instagram_media_caption: 'Promoted Instagram Media Caption',
  adset_id: 'Ad Set',
  age: 'Age',
  campaign_id: 'Campaing',
  country: 'Country',
  device_platform: 'Device Platform',
  gender: 'Gender',
  hourly_stats_aggregated_by_advertiser_time_zone:
    'Time Of Day (Ad Account Time Zone)',
  hourly_stats_aggregated_by_audience_time_zone:
    "Time Of Day (viewer's Time Zone)",
  impression_device: 'Impression Device',
  objective: 'Objective',
  placement: 'Placement',
  platform_position: 'Platform Position',
  publisher_platform: 'Publisher Platform',
  region: 'Region',
}
