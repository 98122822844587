import styled from 'styled-components'
import { ColResizer } from '@mm/ui/src/components/SharedTable/styles'
import { TableHeaderCellStyleProp } from './types'

export const TableHeaderCell = styled.th<TableHeaderCellStyleProp>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #fff;
  font-weight: 500;
  vertical-align: bottom;
  left: 0;

  &.column-group {
    justify-content: center;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  &:hover {
    ${ColResizer} {
      opacity: 1;
    }
  }

  @media (min-width: 1024px) {
    ${({ isSticky, isExportPdf }) =>
      isSticky &&
      !isExportPdf &&
      `
      position: sticky;
      left: 0;
      z-index: 999;
    `}
  }
`

export const TableBody = styled.tbody`
  display: grid;
  position: relative;

  .tbody {
    background-color: #fff;

    &:hover {
      background-color: #fafafa !important;
      th,
      td {
        background-color: #fafafa !important;
      }
    }
  }
`

export const TableBodyRow = styled.tr`
  width: 100%;
  display: flex;
  position: absolute;

  &:last-child {
    border-bottom: 1px solid #d9d9d9;
  }
`

export const TableBodyHeaderCell = styled.th<{ isExportPdf: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.75rem;
  background-color: #fff;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  position: static;
  left: 0;

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      text-transform: lowercase;
      &:hover {
        color: #646eeb;
      }
    }
  }

  @media (min-width: 1024px) {
    ${({ isExportPdf }) =>
      !isExportPdf &&
      `
    
      position: sticky;
      left: 0;
      z-index: 1;
    `}
  }
`

export const TableBodyCellData = styled(TableHeaderCell)`
  border: none !important;
`

export const TableBodyRowCell = styled.td`
  padding: 0.75rem;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
`

export const TableFooter = styled.tfoot`
  display: grid;
  position: relative;
  border-bottom: 1px solid #d9d9d9;

  @media (min-width: 992px) {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  &:hover {
    background-color: #fafafa !important;
    th,
    td {
      background-color: #fafafa !important;
    }
  }
`

export const TableFooterRow = styled.tr`
  width: 100%;
  display: flex;
`

export const TableFooterRowCell = styled(TableBodyRowCell)`
  font-weight: 500;
`

export const TableFooterHeaderCell = styled(TableBodyHeaderCell)``
